import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {SUCCESS} from 'src/styles/colors';
import {H6, P3} from 'src/styles/typography';
import Icon from 'react-native-vector-icons/FontAwesome';
import {Spacing} from 'src/styles';

const SuccessMessage = ({title, message}: any) => {
  return (
    <View style={styles.successMessageContainer}>
      <View style={styles.row}>
        <Icon
          name="check-circle"
          size={18}
          color={SUCCESS}
          style={styles.headerIcon}
        />
        <Text style={[H6, styles.successTitle]}>{title}</Text>
      </View>
      <Text style={[P3, styles.successMessage]}>{message}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerIcon: {
    marginLeft: Spacing.SCALE_8,
  },
  successMessageContainer: {
    backgroundColor: '#e8fbe8',
    height: undefined,
    width: '100%',
    padding: 18,
    borderRadius: 5,
  },
  successTitle: {
    color: SUCCESS,
    marginLeft: 18,
  },
  successMessage: {
    color: SUCCESS,
    marginTop: 18,
    marginLeft: 42,
  },
});

export default SuccessMessage;
