import React from 'react';
import Collapsible from 'react-native-collapsible';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {Checkbox, RadioButton} from 'react-native-paper';
import {useStyle} from 'src/providers/style';
import _ from 'lodash';

const intensityArray = [
  {title: 'Mild', value: 'mild'},
  {title: 'Moderate', value: 'moderate'},
  {title: 'Severe', value: 'severe'},
];

const BehaviorABC = ({
  program,
  expandABCView,
  setExpandABCView,
  intensity,
  changeIntensity,
  prompts,
  checkPrompt,
  currentSet,
}: any) => {
  const styles = useStyle();

  return (
    <Collapsible collapsed={!expandABCView} renderChildrenCollapsed={false}>
      <View
        style={[styles.width, styles.borderBottomWidth, styles.borderColorGray]}
      />
      <ScrollView
        style={[styles.maxHeight250]}
        contentContainerStyle={[styles.paddingHorizontal, styles.marginLTop]}>
        {program?.intensity && (
          <View
            style={[
              styles.positionRelative,
              styles.zIndex100,
              styles.paddingBottom,
            ]}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <Text
                style={[
                  Typography.P2,
                  styles.textColorSecondary,
                  styles.marginSMVertical,
                ]}>
                Intensity
              </Text>
              <Text
                style={[
                  Typography.P2,
                  styles.marginSMVertical,
                  styles.textTransformCapitalize,
                  intensity === 'mild'
                    ? styles.mildColor
                    : intensity === 'moderate'
                    ? styles.moderateColor
                    : styles.severeColor,
                ]}>
                {_.capitalize(intensity)}
              </Text>
            </View>
            <RadioButton.Group
              onValueChange={changeIntensity}
              value={intensity}>
              <View style={[styles.row, styles.justifySpaceBetween]}>
                {intensityArray.map((button: any, index: number) => {
                  return (
                    <View
                      key={`radio-key-${button?.value}-${index}`}
                      style={[styles.column, styles.alignCenter]}>
                      <RadioButton.Android
                        value={button.value}
                        color={
                          intensity === 'mild'
                            ? '#4FF5AF'
                            : intensity === 'moderate'
                            ? '#F7AA4F'
                            : '#F12E5D'
                        }
                        uncheckedColor={'#909093'}
                      />
                      <Text
                        style={[
                          Typography.P2,
                          button.title === _.capitalize(intensity)
                            ? button.title === 'Mild'
                              ? styles.mildColor
                              : button.title === 'Moderate'
                              ? styles.moderateColor
                              : styles.severeColor
                            : styles.textColorSecondary,
                        ]}>
                        {button.title}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </RadioButton.Group>
          </View>
        )}
        {Object.keys(prompts).map((promptType: any) => {
          return (
            <View
              style={[styles.positionRelative]}
              key={`promptType-key-${promptType}`}>
              <Text
                style={[
                  Typography.P2,
                  styles.marginSMVertical,
                  styles.textColorDisabled,
                ]}>
                {promptType}
              </Text>
              {prompts[promptType].map((prompt: any) => {
                return (
                  <TouchableOpacity
                    key={`prompt-key-${promptType}-${prompt?.id}`}
                    style={[
                      styles.row,
                      styles.alignCenter,
                      styles.marginSMVertical,
                    ]}
                    onPress={() => {
                      checkPrompt(prompt?.id);
                    }}>
                    <Checkbox.Android
                      status={
                        (currentSet?.prompts || []).findIndex(v => {
                          return v === prompt?.id;
                        }) !== -1
                          ? 'checked'
                          : 'unchecked'
                      }
                      color={Colors.RAVEN_WHITE}
                      uncheckedColor={Colors.RAVEN_WHITE}
                    />
                    <Text style={[Typography.P2, styles.textColorWhite]}>
                      {prompt?.name}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        })}
      </ScrollView>
      <TouchableOpacity
        style={[
          styles.padding,
          styles.backgroundColorBlue,
          styles.borderBottomLeftRadius,
          styles.borderBottomRightRadius,
        ]}
        onPress={() => setExpandABCView(!expandABCView)}>
        <Text
          style={[
            Typography.PRIMARY_BUTTON,
            styles.textColorWhite,
            styles.textAlignCenter,
          ]}>
          FINISHED
        </Text>
      </TouchableOpacity>
    </Collapsible>
  );
};

export default BehaviorABC;
