import React, {useEffect, useState} from 'react';
import moment from 'moment';
import _ from 'lodash';
import Graph from '../graph';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {endOfDay, startOfDay} from 'date-fns';

const RateProgressGraph = ({
  sessions = [],
  program = {},
  envs = [],
  sets = [],
  events = [],
  title = null,
  extras = {},
  startDate = '',
  endDate = '',
  filteredEnvItems = [],
}: any) => {
  const database = useDatabase();
  const [analysis, setAnalysis] = useState<any>({});
  const eventsBySetId = _.groupBy(events, 'set.id');
  const sortedEnvs = envs.filter(
    env =>
      moment(env.date).isSameOrAfter(moment(startOfDay(new Date(startDate)))) &&
      moment(env.date).isSameOrBefore(moment(endOfDay(new Date(endDate)))),
  );

  const calculateData = async () => {
    const dataArr = [...sets, ...sortedEnvs].sort((a, b) => {
      const dateA = a.startTimestamp || a.date;
      const dateB = b.startTimestamp || b.date;

      return new Date(dateA) - new Date(dateB);
    });
    let data = dataArr.map((datum: any) => {
      const duration = moment
        .duration(
          moment(datum?.endTimestamp).diff(moment(datum?.startTimestamp)),
        )
        .asMinutes();

      if (program.analysis[datum.sessionId]) {
        return {
          ...program.analysis[datum.sessionId],
          ...{
            startTimestamp: datum?.date ? datum?.date : datum?.startTimestamp,
            duration: eventsBySetId[datum.id]?.length / duration,
          },
        };
      } else {
        return {
          ...datum,
          startTimestamp: datum?.date,
          type: datum?.type,
          title: datum.title,
          description: datum.description,
        };
      }
    });

    let phaseChanges = [];
    let envLineData = [];
    let lines = [];
    let currentState = {
      state: '',
      aggregate: 0,
      num: 0,
      index: 1,
    };

    const environmentalObjects = _.groupBy(envs, (env: any) => {
      return moment(env.date).format('YYYY-MM-DD');
    });

    let groupedKeys = {};
    let maxY = 0;

    for (const key in environmentalObjects) {
      if (
        moment(key).isSameOrAfter(moment(startOfDay(new Date(startDate)))) &&
        moment(key).isSameOrBefore(moment(endOfDay(new Date(endDate))))
      ) {
        const environmentalItems = _.groupBy(
          environmentalObjects[key],
          (env: any) => env?.type,
        );
        groupedKeys[key] = {
          dates: [...(groupedKeys[key]?.dates || []), key],
          change: [
            ...(environmentalItems?.change ? environmentalItems.change : []),
          ],
          factor: [
            ...(environmentalItems?.factor ? environmentalItems.factor : []),
          ],
        };
      }
    }

    for (const [i, session] of data.entries()) {
      if (session.sessionId) {
        const pointsOnly = lines.filter(line => line.type === 'point');
        let latestLine = pointsOnly?.[pointsOnly.length - 1] || {
          state: '',
          points: [],
          type: 'point',
        };

        if (session?.average > maxY) {
          maxY = session?.average;
        }

        let collectors = [];
        if (session?.collectors) {
          collectors = await Promise.all(
            session?.collectors?.map(async (collectorId: string) => {
              if (collectorId) {
                if (collectorId !== 'System') {
                  const collector = await database
                    .get('users')
                    .find(collectorId);
                  return `${collector?.firstName} ${collector?.lastName}`;
                } else {
                  return collectorId;
                }
              } else {
                return '';
              }
            }),
          );
        }

        if (latestLine.state === session.state) {
          latestLine.points.push({
            x: i + 1,
            y: session?.average,
            duration: session?.duration,
            date: session?.startTimestamp,
            collectors,
          });
          currentState = {
            ...currentState,
            aggregate: currentState.aggregate + i + 1,
            num: currentState.num + 1,
          };
        } else {
          phaseChanges.push({
            line: currentState.index,
            x: currentState.aggregate / currentState.num,
            state: latestLine.state,
          });
          latestLine = {
            state: session.state,
            points: [
              {
                x: i + 1,
                y: session?.average,
                date: session?.startTimestamp,
                duration: session?.duration,
                collectors,
              },
            ],
            type: 'point',
          };
          currentState = {
            state: session.state,
            aggregate: i + 1,
            num: 1,
            index: i + 1,
          };
        }

        if (latestLine.points.length === 1) {
          lines.push(latestLine);
        } else {
          lines[lines.length - 1] = latestLine;
        }
      } else if (session.type === 'change') {
        lines.push({
          x: i + 1,
          y: 50,
          type: 'change',
          values: {
            [moment(session.startTimestamp).format('YYYY-MM-DD')]: [
              {
                date: session.startTimestamp,
                title: session.title,
                description: session.description,
              },
            ],
          },
        });
      } else if (session.type === 'factor') {
        lines.push({
          x: i + 1,
          y: 50,
          type: 'factor',
          values: {
            [moment(session.startTimestamp).format('YYYY-MM-DD')]: [
              {
                date: session.startTimestamp,
                title: session.title,
                description: session.description,
              },
            ],
          },
        });
      }
    }
    phaseChanges.push({
      line: currentState.index,
      x: currentState.aggregate / currentState.num,
      state: currentState.state,
    });

    setAnalysis({data, envLineData, phaseChanges, lines, maxY});
  };

  useEffect(() => {
    calculateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions, envs]);

  return (
    <Graph
      yAxisLabel={'Rate'}
      data={analysis.data}
      program={program}
      envs={analysis.envLineData}
      phaseChanges={analysis.phaseChanges}
      lines={analysis.lines}
      maxY={analysis.maxY}
      title={title}
      extras={extras}
      filteredEnvItems={filteredEnvItems}
    />
  );
};

export default React.memo(RateProgressGraph);
