import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import HookFormSelectInput from 'src/hook-form-wrapper/select-input';

export const credentialsList: any[] = [
  {label: 'BCaBa - Board Certified Assistant Behavior Analyst', value: 'BCaBA'},
  {label: 'BCBA - Board Certified Behavior Analyst', value: 'BCBA'},
  {
    label: 'BCBA-D - Board Certified Behavior Analyst-Doctoral',
    value: 'BCBA-D',
  },
  {label: 'BT - Behavioral Technician', value: 'BT'},
  {label: 'CBA - Certified Behavior Analyst', value: 'CBA'},
  {
    label:
      'CCC-SLP - Certificate of Clinical Competence in Speech-Language Pathology',
    value: 'CCC-SLP',
  },
  {label: 'Ed.S. - Education Specialist', value: 'Ed.S.'},
  {label: 'ITDS - Infant Toddler Developmental Specialist', value: 'ITDS'},
  {label: 'LABA - Licensed Applied Behavior Analysts', value: 'LABA'},
  {
    label: 'LAABA - Licensed Assistant Applied Behavior Analysts',
    value: 'LAABA',
  },
  {
    label: 'LBA - Licensed Behavior Analyst',
    value: 'LBA',
  },
  {label: 'LBS - Licensed Behavior Specialist', value: 'LBS'},
  {
    label: 'LICSW - Licensed Independent Clinical Social Worker',
    value: 'LICSW',
  },
  {label: 'LMFT - Licensed Marriage and Family Therapist', value: 'LMFT'},
  {label: 'M.Ed. - Master of Education', value: 'M.Ed.'},
  {label: 'MA - Master of Arts', value: 'MA'},
  {label: 'MS - Master of Science', value: 'MS'},
  {label: 'M.Sc. - Master of Science', value: 'M.Sc.'},
  {label: 'MSEd - Master of Science in Education', value: 'MSEd'},
  {label: 'MPH - Master of Public Health', value: 'MPH'},
  {label: 'NCSP - Nationally Certified School Psychologist', value: 'NCSP'},
  {label: 'PhD - Doctor of Philosophy', value: 'PHD'},
  {label: 'QSP - Qualified Supervising Professional', value: 'QSP'},
  {label: 'RBT - Registered Behavioral Technician', value: 'RBT'},
  {label: 'RLT - Registered Line Technician', value: 'RLT'},
  {label: 'SCABA - State Certified Assistant Behavior Analyst', value: 'SCABA'},
  {label: 'LPC - Licensed Professional Counselor', value: 'LPC'},
];

interface DisplayProps {
  value: string[];
}

interface InputProps {
  name?: string;
  label?: string;
}

export const Import = (
  key: string = 'credential',
  prefix?: string,
): IDeveloperField => ({
  label:
    (prefix ? prefix + ' ' : '') +
    `Credential (${credentialsList.map(value => value.label).join(', ')})`,
  key,
  type: 'select',
  selectOptions: credentialsList,
});

const resolveDisplayValue = (value: string[]) => {
  return value
    .map(val => credentialsList.find(item => item.value === val)?.value)
    .join(', ');
};

const CredentialDisplay = ({value}: DisplayProps) => {
  return (
    <DataItem>
      <Text style={[Typography.H6]}>Credential</Text>
      <Text style={[Typography.P3]}>{resolveDisplayValue(value)}</Text>
    </DataItem>
  );
};

const CredentialInput = ({name, label = 'Credentials'}: InputProps) => {
  return (
    <HookFormSelectInput
      name={(name ? name + '.' : '') + 'credentials'}
      label={label}
      required={false}
      multiple={true}
      selectAll={false}
      items={credentialsList}
    />
  );
};

export const validation = () =>
  Yup.string().required('Credentials is required');

export const Display = CredentialDisplay;
export const Input = CredentialInput;
export const Value = resolveDisplayValue;

export default CredentialInput;
