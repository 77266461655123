import {text, relation, field} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class SessionTarget extends Base {
  static table = 'session_targets';
  static associations = {
    session_programs: {type: 'belongs_to', key: 'session_program_id'},
    targets: {type: 'belongs_to', key: 'target_id'},
  };

  @relation('session_programs', 'session_program_id') sessionProgram;
  @relation('targets', 'target_id') target;

  @text('_partition') partition;
  @text('rank') rank;
  @field('enabled') enabled;
}
