import moment from 'moment/moment';
import _ from 'lodash';

export const exportFrequency = async (database: any, events: any[]) => {
  let _exportData = [['Date', 'Time Stamp', 'Instance', 'Intensity', 'ABC']];
  for (const event of events) {
    const index: number = events.indexOf(event);

    let abcStr = '';
    for (const promptId of event?.prompts) {
      const prompt = await database.get('prompts').find(promptId);
      abcStr += prompt.name.replaceAll('"', "'") + '\n';
    }

    _exportData.push([
      moment(event?.createdAt).format('MM/DD/YY'),
      moment(event?.createdAt).format('hh:mm:ss A'),
      `${index + 1}`,
      _.capitalize(event?.intensity),
      abcStr,
    ]);
  }
  return _exportData;
};
