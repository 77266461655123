import React, {useState} from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import PaperMenu from 'src/design-system/paper-menu';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import moment from 'moment';
import _ from 'lodash';
import {of} from 'rxjs';
import {PromptsDisplay} from 'src/modules/session/components';
import {Typography} from 'src/styles';
import {Prompt} from 'src/models';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

const FrequencyTableTooltip = ({
  edit = false,
  setEvents,
  setInstance,
  event,
  promptItems,
  instance,
  intensity,
  collector,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();

  const promptTypes = _.groupBy(promptItems, 'promptType');

  const [visible, setVisible] = useState(false);

  const openToolTip = () => setVisible(true);
  const closeTooltip = () => setVisible(false);

  if (edit) {
    return (
      <TouchableOpacity
        onPress={() => {
          setEvents(event);
          setInstance(instance);
        }}>
        <View style={[styles.paddingMVertical, styles.alignCenter]}>
          <Text>{_.capitalize(event?.intensity)}</Text>
        </View>
        {/* {event?.prompts?.length ? (
          <PromptsDisplay prompts={event?.prompts} />
        ) : (
          <></>
        )} */}
      </TouchableOpacity>
    );
  } else {
    return (
      <PaperMenu
        visible={visible}
        onDismiss={closeTooltip}
        anchor={
          <TouchableOpacity style={styles.alignCenter} onPress={openToolTip}>
            <View style={[styles.paddingMVertical, styles.alignCenter]}>
              <Text style={Typography.CAPTION}>
                {moment(event?.createdAt).format('ll')}
              </Text>
              <Text style={Typography.CAPTION}>
                {moment(event?.createdAt).format('hh:mm:ss A')}
              </Text>
            </View>
            {event?.prompts?.length ? (
              <PromptsDisplay prompts={event?.prompts} />
            ) : (
              <></>
            )}
          </TouchableOpacity>
        }>
        <View style={styles.p_10}>
          <Text style={[Typography.CAPTION_MEDIUM]}>
            {translations('date')}
          </Text>
          <Text style={Typography.CAPTION}>
            {moment(event?.createdAt).format('LL')}
          </Text>

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
            {translations('time_of_occurrence')}
          </Text>
          <Text style={Typography.CAPTION}>
            {moment(event?.createdAt).format('hh:mm:ss A')}
          </Text>

          <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
            {translations('instance')}
          </Text>
          <Text style={Typography.CAPTION}>{instance}</Text>

          {collector && (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
                {translations('collector')}
              </Text>
              <Text
                style={
                  Typography.CAPTION
                }>{`${collector?.firstName} ${collector?.lastName}`}</Text>
            </>
          )}

          {promptItems?.length > 0 ? (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
                {translations('abc')}
              </Text>
              {Object.keys(promptTypes).map((promptType: string) => {
                return (
                  <View key={`promptType-key-${promptType}`}>
                    <Text
                      style={
                        (Typography.CAPTION_MEDIUM, styles.marginSMBottom)
                      }>
                      {promptType}
                    </Text>
                    <View>
                      {promptTypes[promptType].map((prompt: any) => (
                        <Text
                          style={Typography.CAPTION}
                          key={`prompt-${prompt?.id}`}>
                          {prompt?.name}
                        </Text>
                      ))}
                    </View>
                  </View>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {intensity !== '' && (
            <>
              <Text style={[Typography.CAPTION_MEDIUM, styles.marginMTop]}>
                {translations('intensity')}
              </Text>
              <Text style={Typography.CAPTION}>{intensity}</Text>
            </>
          )}
        </View>
      </PaperMenu>
    );
  }
};
export default compose(
  withDatabase,
  withObservables([], ({database, event}: any) => {
    const collector =
      event?.createdBy &&
      event?.createdBy !== '' &&
      event?.createdBy !== 'System'
        ? database.get('users').findAndObserve(event.createdBy)
        : of(undefined);
    return {
      promptItems: event?.prompts
        ? database
            .get(Prompt.table)
            .query(Q.where('id', Q.oneOf(event?.prompts)))
        : of([]),
      collector,
    };
  }),
)(FrequencyTableTooltip);
