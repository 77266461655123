import React, {useEffect, useState} from 'react';
import {Platform, SafeAreaView} from 'react-native';
import Navigation from 'src/navigation';
import {useDispatch} from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import sync from 'src/providers/watermelondb/sync';
import {checkLogin} from 'src/redux/auth';
import SVG from 'src/styles/svg';
import SplashScreen from 'react-native-splash-screen';
import {
  getTrackingStatus,
  TrackingStatus,
} from 'react-native-tracking-transparency';
import {requestTrackingPermission} from 'react-native-tracking-transparency/src';
import {Lottie} from 'src/design-system';
import {Separator} from 'src/common-components/atoms';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useStyle} from 'src/providers/style';

const Application = () => {
  const database = useDatabase();
  const dispatch = useDispatch();
  const styles = useStyle();

  const [isLoading, setIsLoading] = useState(true);
  // const [syncFail, setSyncFail] = useState(false);
  const [initialRoute, setInitialRoute] = useState('Login');
  const [splashHidden, setSplashHidden] = useState(false);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      setTimeout(() => {
        SplashScreen.hide();
        setSplashHidden(true);
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let interval = setInterval(() => {
        sync(database, false, false, false);
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (splashHidden) {
      getTrackingStatus().then(async (value: TrackingStatus) => {
        if (value === 'not-determined') {
          await requestTrackingPermission();
        }
      });
    }
  }, [splashHidden]);

  useEffect(() => {
    const initialize = async () => {
      if (await AsyncStorage.getItem('accessToken')) {
        sync(database, false, false, true).catch(e => {
          // setSyncFail(true);
          console.log(e);
        });

        const response = await dispatch(checkLogin());
        if (response.payload.selectedGroup) {
          setInitialRoute('Dashboard');
        } else {
          setInitialRoute('OrganizationSelection');
        }
        setIsLoading(false);
      } else {
        setInitialRoute('Login');
        setIsLoading(false);
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <SafeAreaView
          style={[
            styles.flex,
            styles.column,
            styles.alignCenter,
            styles.justifyCenter,
          ]}>
          <SVG height={30} name={'logo'} />
          <Separator height={40} />
          <Lottie src={require('../../assets/loading.json')} />
        </SafeAreaView>
      ) : (
        <>
          {/* <Modal
            show={[syncFail, setSyncFail]}
            title="Error"
            closeButton={false}>
            <Text style={[Typography.P1]}>
              We encountered an issue connecting to the server. Please refresh
              your browser or try logging in again.
            </Text>
          </Modal> */}

          <SafeAreaView style={[styles.flex]}>
            <Navigation initialRoute={initialRoute} />
          </SafeAreaView>
        </>
      )}
    </>
  );
};

export default Application;
