import {Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import React, {useMemo, useRef, useState} from 'react';
import {useNavigation} from '@react-navigation/core';
import ViewShot from 'react-native-view-shot';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {IconButton, Snackbar} from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import moment from 'moment';
import _ from 'lodash';
import {
  DurationProgressGraph,
  FrequencyProgressGraph,
  IntervalProgressGraph,
  TaskAnalysisProgressGraph,
  TrialByTrialProgress,
  RateProgressGraph,
} from 'src/modules/reports/components';
import {Colors, Typography} from 'src/styles';
import {ProgramMethodAvatar} from 'src/modules/programs/components';
import {collectionMethods} from 'src/modules/programs/components/program-card/collection';
import {ProgramProfile} from 'src/modules';
import {Menu} from 'src/design-system';
import {analyzePrograms} from 'src/common-utils/analyzePrograms';
import {
  exportDuration,
  exportFrequency,
  exportInterval,
  exportRate,
  exportTaskAnalysis,
  exportTrialByTrial,
} from 'src/common-utils/program-evaluation';
import {exportCsv} from 'src/common-utils/export-csv';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {of} from 'rxjs';
import {exportGraph} from 'src/common-utils/export-graph';
import {
  DurationTable,
  FrequencyTable,
  IntervalTable,
  RateTable,
  TaskAnalysisTable,
} from 'src/modules/session/components';
import {EnvironmentalFactor} from 'src/models';
import {endOfDay, startOfDay} from 'date-fns';

const ProgramGraph = ({
  patient,
  program,
  sets,
  totalSets,
  targets,
  envs,
  events,
  totalEvents,
  programSessions = [],
  totalProgramSessions = [],
  collapsable = true,
  showTable = false,
  startDate,
  endDate,
  filteredEnvItems = [],
}: any) => {
  const isWeb = Platform.OS === 'web';
  const graphExportRef = useRef();
  const tableExportRef = useRef();
  const database = useDatabase();
  const navigation = useNavigation();
  const [currentTarget, setCurrentTarget] = useState(targets?.[0]?.id);
  const [collapsed, setCollapsed] = useState(false);
  const [showExportDlg, setShowExportDlg] = useState<string | undefined>(
    undefined,
  );
  const [showProgramObjective, setShowProgramObjective] = useState(false);

  const method = collectionMethods.find(
    collectionMethod => collectionMethod.value === program?.method,
  );

  const analyzedProgram = useMemo(() => {
    return analyzePrograms(
      program,
      totalSets,
      totalEvents,
      totalProgramSessions,
      false,
      [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program.updatedAt, sets, currentTarget]);

  const exportPDF = async () => {
    await exportGraph(graphExportRef, program?.name, 'pdf');
    setShowExportDlg(`${program?.name}.pdf`);
  };

  const exportJpeg = async () => {
    await exportGraph(graphExportRef, program?.name, 'jpg');
    setShowExportDlg(`${program?.name}.jpg`);
  };

  const exportPng = async () => {
    await exportGraph(graphExportRef, program?.name, 'png');
    setShowExportDlg(`${program?.name}.png`);
  };

  const exportTablePDF = async () => {
    await exportGraph(tableExportRef, `${program?.name}-table`, 'pdf');
    setShowExportDlg(`${program?.name}.pdf`);
  };

  const exportTableJpeg = async () => {
    await exportGraph(tableExportRef, `${program?.name}-table`, 'jpg');
    setShowExportDlg(`${program?.name}.jpg`);
  };

  const exportTablePng = async () => {
    await exportGraph(tableExportRef, `${program?.name}-table`, 'png');
    setShowExportDlg(`${program?.name}.png`);
  };

  const createCSVFile = async () => {
    let data: any[] = [];
    switch (program.method) {
      case 'duration':
        data = await exportDuration(database, sets);
        break;
      case 'frequency':
        data = await exportFrequency(database, events);
        break;
      case 'interval':
        data = await exportInterval(database, sets);
        break;
      case 'rate':
        data = await exportRate(database, sets, events);
        break;
      case 'trial_by_trial':
        data = await exportTrialByTrial(
          database,
          sets,
          currentTarget,
          program?.numberOfTrials,
        );
        break;
      case 'task_analysis':
        data = await exportTaskAnalysis(database, sets, targets);
        break;
    }

    await exportCsv(
      `${patient?.lastName}_${program?.name}_${moment().format('YYYY-MM-DD')}`,
      data,
    );
  };

  const options = [
    {
      onPress: exportPDF,
      title: 'PDF',
      icon: 'file-pdf-box',
    },
    {
      onPress: exportJpeg,
      title: 'JPEG',
      icon: 'file-jpg-box',
    },
    {
      onPress: exportPng,
      title: 'PNG',
      icon: 'file-png-box',
    },
  ];

  const tableOptions = [
    {
      onPress: exportTablePDF,
      title: 'PDF',
      icon: 'file-pdf-box',
    },
    {
      onPress: createCSVFile,
      title: 'CSV',
      icon: 'file-excel',
    },
    {
      onPress: exportTableJpeg,
      title: 'JPEG',
      icon: 'file-jpg-box',
    },
    {
      onPress: exportTablePng,
      title: 'PNG',
      icon: 'file-png-box',
    },
  ];

  const title = (
    <View
      style={[
        styles.justifySpaceBetween,
        styles.alignCenter,
        styles.padding20,
        /* eslint-disable-next-line react-native/no-inline-styles */
        {flexDirection: isWeb ? 'row' : 'column'},
      ]}>
      {isWeb ? (
        <Text
          style={
            styles.programNameTxt
          }>{`${patient?.firstName} ${patient?.lastName}`}</Text>
      ) : null}
      <View style={[styles.alignCenter, styles.maxWidth66]}>
        <Text style={styles.programNameTxt}>{program?.name}</Text>
        {showProgramObjective && program?.objective ? (
          <Text style={styles.programNameTxt}>{program?.objective}</Text>
        ) : null}
        {method?.title === 'Trial by Trial' ? (
          <Text style={styles.programNameTxt}>
            {`Target: ${
              targets.find(target => target.id === currentTarget)?.name
            }`}
          </Text>
        ) : null}
      </View>
      <Text style={styles.programNameTxt}>
        Date Range: {moment(startDate).format('MM/DD/YY')} -{' '}
        {moment(endDate).format('MM/DD/YY')}
      </Text>
    </View>
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.rowContainer}
        onPress={() => {
          collapsable ? setCollapsed(!collapsed) : null;
        }}>
        <View style={styles.flexRow}>
          <View style={styles.iconContainer}>
            <ProgramMethodAvatar type={program?.method} />
          </View>
          <View>
            <Text
              style={
                styles.programNameTxt
              }>{`${patient?.firstName} ${patient?.lastName}`}</Text>
            <Text style={styles.programNameTxt}>{program?.name}</Text>
            <Text style={styles.programMethodTxt}>
              {program?.type === 'behavior'
                ? 'Behavior Tracker'
                : 'Skill Acquisition'}{' '}
              | {method?.title}
            </Text>
          </View>
        </View>
        <View style={[styles.flexRow, styles.alignCenter]}>
          {isWeb ? (
            <Menu
              anchor={<AntDesign size={20} name="download" />}
              options={options}
            />
          ) : null}
          <IconButton
            color={Colors.RAVEN_BLACK}
            icon={'trending-up'}
            onPress={() =>
              navigation.navigate(ProgramProfile.screenName, {
                programId: program.id,
                screen: 'Summary',
              })
            }
          />
          {collapsable ? (
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
            />
          ) : (
            <></>
          )}
        </View>
      </TouchableOpacity>
      <View style={[]}>
        <Collapsible collapsed={collapsed} style={styles.padding}>
          {program?.method === 'trial_by_trial' ? (
            <TrialByTrialProgress
              program={program}
              sets={totalSets}
              recentSets={sets}
              events={totalEvents}
              recentEvents={events}
              sessions={totalProgramSessions}
              targets={targets}
              currentTarget={currentTarget}
              setCurrentTarget={setCurrentTarget}
              table={showTable}
              envs={envs}
              filteredEnvItems={filteredEnvItems}
              title={title}
              startDate={startDate}
              endDate={endDate}
              extras={{
                showExport: true,
                graphExportRef,
                tableExportRef,
                options,
                tableOptions,
                title,
                showProgramObjective,
                setShowProgramObjective,
              }}
            />
          ) : programSessions?.length === 0 ||
            analyzedProgram.analysisState?.length === 0 ? (
            <View style={styles.emptyContainer}>
              <Icon name="chart-areaspline" size={30} color={'grey'} />
              <Text style={styles.emptyTitle}>No data</Text>
            </View>
          ) : program?.method === 'task_analysis' ? (
            <>
              <TaskAnalysisProgressGraph
                program={analyzedProgram}
                sessions={programSessions}
                envs={envs}
                title={title}
                startDate={startDate}
                endDate={endDate}
                extras={{
                  showExport: true,
                  graphExportRef,
                  showProgramObjective,
                  setShowProgramObjective,
                }}
                filteredEnvItems={filteredEnvItems}
              />
              {isWeb ? (
                <View style={[styles.paddingTop, styles.zIndexNegative]}>
                  <View
                    style={[
                      styles.paddingVertical,
                      styles.paddingHorizontal,
                      styles.alignSelfEnd,
                    ]}>
                    <Menu
                      anchor={<AntDesign size={20} name="download" />}
                      options={tableOptions}
                    />
                  </View>
                  <ViewShot ref={tableExportRef}>
                    {title}
                    <TaskAnalysisTable
                      program={program}
                      sets={sets}
                      targets={targets}
                    />
                  </ViewShot>
                </View>
              ) : null}
            </>
          ) : program?.method === 'duration' ? (
            <>
              <DurationProgressGraph
                program={analyzedProgram}
                sessions={programSessions}
                envs={envs}
                title={title}
                sets={sets}
                startDate={startDate}
                endDate={endDate}
                extras={{
                  showExport: true,
                  graphExportRef,
                  showProgramObjective,
                  setShowProgramObjective,
                }}
                filteredEnvItems={filteredEnvItems}
              />
              {isWeb ? (
                <View style={[styles.paddingTop, styles.zIndexNegative]}>
                  <View
                    style={[
                      styles.paddingVertical,
                      styles.paddingHorizontal,
                      styles.alignSelfEnd,
                    ]}>
                    <Menu
                      anchor={<AntDesign size={20} name="download" />}
                      options={tableOptions}
                    />
                  </View>
                  <ViewShot ref={tableExportRef}>
                    {title}
                    <DurationTable program={program} sets={sets} />
                  </ViewShot>
                </View>
              ) : null}
            </>
          ) : program?.method === 'rate' ? (
            <>
              <RateProgressGraph
                program={analyzedProgram}
                sessions={programSessions}
                envs={envs}
                title={title}
                sets={sets}
                events={events}
                startDate={startDate}
                endDate={endDate}
                extras={{
                  showExport: true,
                  graphExportRef,
                  showProgramObjective,
                  setShowProgramObjective,
                }}
                filteredEnvItems={filteredEnvItems}
              />
              {isWeb ? (
                <View style={[styles.paddingTop, styles.zIndexNegative]}>
                  <View
                    style={[
                      styles.paddingVertical,
                      styles.paddingHorizontal,
                      styles.alignSelfEnd,
                    ]}>
                    <Menu
                      anchor={<AntDesign size={20} name="download" />}
                      options={tableOptions}
                    />
                  </View>
                  <ViewShot ref={tableExportRef}>
                    {title}
                    <RateTable program={program} sets={sets} />
                  </ViewShot>
                </View>
              ) : null}
            </>
          ) : program?.method === 'frequency' ? (
            <>
              <FrequencyProgressGraph
                program={analyzedProgram}
                sessions={programSessions}
                envs={envs}
                title={title}
                startDate={startDate}
                endDate={endDate}
                extras={{
                  showExport: true,
                  graphExportRef,
                  showProgramObjective,
                  setShowProgramObjective,
                }}
                filteredEnvItems={filteredEnvItems}
              />
              {isWeb ? (
                <View style={[styles.paddingTop, styles.zIndexNegative]}>
                  <View
                    style={[
                      styles.paddingVertical,
                      styles.paddingHorizontal,
                      styles.alignSelfEnd,
                    ]}>
                    <Menu
                      anchor={<AntDesign size={20} name="download" />}
                      options={tableOptions}
                    />
                  </View>
                  <ViewShot ref={tableExportRef}>
                    {title}
                    <FrequencyTable program={program} sets={sets} />
                  </ViewShot>
                </View>
              ) : null}
            </>
          ) : program?.method === 'interval' ? (
            <>
              <IntervalProgressGraph
                program={analyzedProgram}
                sessions={programSessions}
                envs={envs}
                graph={'bar'}
                title={title}
                sets={sets}
                events={events}
                startDate={startDate}
                endDate={endDate}
                extras={{
                  showExport: true,
                  graphExportRef,
                  showProgramObjective,
                  setShowProgramObjective,
                }}
                filteredEnvItems={filteredEnvItems}
              />
              {isWeb ? (
                <View style={[styles.paddingTop, styles.zIndexNegative]}>
                  <View
                    style={[
                      styles.paddingVertical,
                      styles.paddingHorizontal,
                      styles.alignSelfEnd,
                    ]}>
                    <Menu
                      anchor={<AntDesign size={20} name="download" />}
                      options={tableOptions}
                    />
                  </View>
                  <ViewShot ref={tableExportRef}>
                    {title}
                    <IntervalTable
                      program={program}
                      sets={sets}
                      events={events}
                    />
                  </ViewShot>
                </View>
              ) : null}
            </>
          ) : (
            <></>
          )}
        </Collapsible>
      </View>
      <Snackbar
        visible={showExportDlg !== undefined}
        onDismiss={() => setShowExportDlg(undefined)}
        duration={3000}
        action={{
          label: 'Dismiss',
          onPress: () => {},
        }}>
        {showExportDlg} was downloaded.
      </Snackbar>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.RAVEN_WHITE,
    borderRadius: 5,
    borderRightColor: '#D3D3D3',
    borderRightWidth: 1,
    borderBottomColor: '#D3D3D3',
    borderBottomWidth: 1,
    marginHorizontal: 20,
    marginVertical: 20,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
  },
  flexRow: {
    flexDirection: 'row',
  },
  iconContainer: {
    height: 33,
    width: 33,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  padding: {
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  programNameTxt: {
    ...Typography.P3,
    fontWeight: '500',
    color: Colors.PRIMARY_900,
  },
  programMethodTxt: {
    ...Typography.CAPTION,
    color: Colors.TEXT_DISABLED,
  },
  alignCenter: {
    alignItems: 'center',
  },
  padding20: {
    padding: 20,
  },
  paddingTop: {
    paddingTop: 20,
  },
  paddingVertical: {
    paddingVertical: 20,
  },
  paddingHorizontal: {
    paddingHorizontal: 20,
  },
  emptyContainer: {
    flex: 1,
    minHeight: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyTitle: {
    ...Typography.P3,
    color: Colors.TEXT_PRIMARY,
    marginTop: 8,
  },
  alignSelf: {
    alignSelf: 'flex-start',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignSelfEnd: {
    alignSelf: 'flex-end',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  flex: {
    flex: 1,
  },
  zIndexNegative: {
    zIndex: -1,
  },
  maxWidth66: {
    maxWidth: '66%',
  },
});

export default compose(
  withDatabase,
  withObservables(['programId', 'patient'], ({database, programId}: any) => {
    return {
      program: database.get('programs').findAndObserve(programId),
    };
  }),
  withObservables(['programId', 'patient'], ({program}: any) => {
    return {
      targets: program.activeTargets,
      patient: program.patient,
    };
  }),
  withObservables(
    ['programId', 'startDate', 'endDate', 'patient'],
    ({database, patient, startDate, endDate}: any) => {
      return {
        totalSessions: database
          .get('sessions')
          .query(
            Q.where('patient_id', patient?.id),
            Q.where('deleted_at', null),
            Q.where('type', Q.eq('session')),
            Q.where('start_timestamp', Q.notEq(null)),
            Q.sortBy('date', Q.asc),
          ),
        sessions: database
          .get('sessions')
          .query(
            Q.where('patient_id', patient?.id),
            Q.where('deleted_at', null),
            Q.where('type', Q.eq('session')),
            Q.where('start_timestamp', Q.notEq(null)),
            Q.where(
              'date',
              Q.between(
                new Date(startDate).getTime(),
                new Date(endDate).getTime(),
              ),
            ),
            Q.sortBy('date', Q.asc),
          ),
        filteredEnvItems: database
          .get(EnvironmentalFactor.table)
          .query(
            Q.where('deleted_at', null),
            Q.where('patient_id', patient?.id),
            Q.where(
              'date',
              Q.between(
                startOfDay(new Date(startDate)).getTime(),
                endOfDay(new Date(endDate)).getTime(),
              ),
            ),
            Q.sortBy('date', Q.desc),
          ),
      };
    },
  ),
  withObservables(
    ['sessions', 'program', 'patient'],
    ({program, sessions, totalSessions, database}: any) => {
      const sessionIds = _.map(sessions, 'id');
      const totalSessionIds = _.map(totalSessions, 'id');
      return {
        sets: database
          .get('sets')
          .query(
            Q.and(
              Q.where('session_id', Q.oneOf(sessionIds)),
              Q.where('program_id', program?.id),
              Q.where('deleted_at', null),
              Q.where('end_timestamp', Q.notEq(null)),
            ),
          ),
        totalSets: database
          .get('sets')
          .query(
            Q.and(
              Q.where('session_id', Q.oneOf(totalSessionIds)),
              Q.where('program_id', program?.id),
              Q.where('deleted_at', null),
              Q.where('end_timestamp', Q.notEq(null)),
            ),
          ),
      };
    },
  ),
  withObservables(
    ['programId', 'patient', 'sets'],
    ({database, patient, sets, totalSets}: any) => {
      const sessionIds = _.map(sets, 'sessionId');
      const totalSessionIDs = _.map(totalSets, 'sessionId');
      const setIds = _.map(sets, 'id');
      const totalSetIds = _.map(totalSets, 'id');
      return {
        events: database
          .get('events')
          .query(
            Q.where('set_id', Q.oneOf(setIds)),
            Q.where('deleted_at', null),
            Q.sortBy('created_at', Q.asc),
          ),
        totalEvents: database
          .get('events')
          .query(
            Q.where('set_id', Q.oneOf(totalSetIds)),
            Q.where('deleted_at', null),
            Q.sortBy('created_at', Q.asc),
          ),
        envs: patient?.sortedEnvironmentalFactors || of([]),
        programSessions: database
          .get('sessions')
          .query(Q.where('id', Q.oneOf(sessionIds)), Q.sortBy('date', Q.asc)),
        totalProgramSessions: database
          .get('sessions')
          .query(
            Q.where('id', Q.oneOf(totalSessionIDs)),
            Q.sortBy('date', Q.asc),
          ),
      };
    },
  ),
)(ProgramGraph);
