import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ActivityIndicator, FlatList, Pressable, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import BaseAuthScreen from 'src/modules/authentication/screens/base-auth-screen';
import {Typography} from 'src/styles';
import * as Colors from 'src/styles/colors';
import {login} from 'src/redux/auth';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {logout} from 'src/redux/auth';
import {useAnalytics} from 'src/providers/segment';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {useNavigation} from '@react-navigation/native';
import sync from 'src/providers/watermelondb/sync';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FileImage from 'src/design-system/file-image';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import RHButton from '../../../../common-components/custom-ui-helpers/RHButton';

const OrganizationSelectionScreen = ({instances}: any) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const navigation = useNavigation();
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const fetchUser = async () => {
    const users = await database
      .get('users')
      .query(Q.and(Q.where('identifier', await AsyncStorage.getItem('id'))))
      .fetch();

    const user = users?.[0];

    setData({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
    });
  };

  const firstName = data?.firstName || '';
  const lastName = data?.lastName || '';
  const email = data?.email || '';

  const selectOrganization = async (partition: any) => {
    const users = await database
      .get('users')
      .query(
        Q.and(
          Q.where('identifier', await AsyncStorage.getItem('id')),
          Q.where('_partition', partition),
        ),
      )
      .fetch();
    if (users.length) {
      setError('');
      await dispatch(
        login({
          userId: users[0].id,
          selectedGroup: partition,
        }),
      );
      analytics.track('User Signed In', {
        username: data?.email,
        context: {
          groupId: partition,
        },
        groupId: [partition],
      });
      navigation.navigate('Dashboard');
    } else {
      setError(translations('auth_screen_sync_error'));
      setIsLoading(true);
      sync(database, false, true).then(() => {
        fetchUser();
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (database) {
      setIsLoading(true);
      sync(database).then(() => {
        fetchUser();
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [database]);

  // const createOrganization = () => {};

  const submitLogout = async () => {
    await database.write(async () => {
      if (!database._isBeingReset) {
        await database.unsafeResetDatabase();
      }
    });
    dispatch(logout());
    navigation.navigate('Login');
  };

  const tryAgain = async () => {
    setIsLoading(true);
    await sync(database, false, true);
    setIsLoading(false);
  };

  return (
    <BaseAuthScreen>
      <Text
        style={[
          Typography.H1,
          styles.textAlignCenter,
          styles.textColorPrimary,
          styles.marginLTop,
        ]}>
        {translations('auth_screen_organizations')}
      </Text>
      <Text
        style={[Typography.P3, styles.textAlignCenter, styles.marginLVertical]}>
        {translations('auth_screen_select_organization_to_access')}
      </Text>
      <View style={[styles.column, styles.alignCenter]}>
        <View
          style={[
            styles.padding,
            styles.backgroundColorTeal300,
            styles.borderRadius,
            styles.menuMaxWidth,
            styles.width,
          ]}>
          <Text style={[Typography.P3]}>
            {firstName} {lastName}
          </Text>
          <Text style={[Typography.P3]}>{email}</Text>
        </View>
        <View
          style={[
            styles.marginVertical,
            styles.padding,
            styles.backgroundColorWhite,
            styles.borderRadius,
            styles.menuMaxWidth,
            styles.width,
            styles.elevation,
          ]}>
          <Text style={[Typography.H4]}>
            {translations('auth_screen_select_organization')}
          </Text>
          {isLoading ? (
            <>
              <Text style={[Typography.P3, styles.textColorPrimary]}>
                {error}
              </Text>
              <ActivityIndicator />
            </>
          ) : (
            <>
              {instances.length === 0 ? (
                <>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    The sync may have failed. Press below to try another
                    attempt.
                  </Text>
                  <View style={[styles.column]}>
                    <RHButton
                      mode="contained"
                      onPress={() => tryAgain()}
                      style={[styles.marginLTop]}>
                      Try Again
                    </RHButton>
                  </View>
                </>
              ) : (
                <>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    These are organizations you have access to:
                  </Text>
                  <FlatList
                    scrollEnabled={false}
                    data={instances}
                    renderItem={({item}) => {
                      return (
                        <Pressable
                          key={item.id}
                          style={({pressed}) => [
                            styles.row,
                            styles.justifySpaceBetween,
                            styles.paddingL,
                            styles.alignItemsCenter,
                            styles.border,
                            styles.borderColorPrimary300,
                            styles.borderRadiusSM,
                            styles.marginSMVertical,
                            {
                              opacity: pressed ? 0.4 : 1,
                            },
                          ]}
                          onPress={() => selectOrganization(item.partition)}>
                          <View
                            style={[
                              styles.flex,
                              styles.row,
                              styles.alignCenter,
                            ]}>
                            {item?.logo ? (
                              <View
                                style={[
                                  styles.width40,
                                  styles.height40,
                                  styles.alignCenter,
                                  styles.justifyCenter,
                                  styles.marginMRight,
                                  styles.overflowHidden,
                                  styles.borderRadius,
                                ]}>
                                <FileImage src={item?.logo} />
                              </View>
                            ) : (
                              <></>
                            )}
                            <Text
                              style={[
                                styles.flex,
                                Typography.P2,
                                styles.textColorPrimary,
                                styles.flexWrap,
                              ]}>
                              {item.name}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.column,
                              styles.alignCenter,
                              styles.justifyCenter,
                            ]}>
                            <Icon
                              name="chevron-right"
                              color={Colors.OPACITY_54}
                              size={20}
                            />
                          </View>
                        </Pressable>
                      );
                    }}
                  />
                </>
              )}
            </>
          )}
        </View>
      </View>
      <View
        style={[
          styles.marginHorizontal,
          styles.justifyCenter,
          styles.alignCenter,
        ]}>
        <Text style={[Typography.P3, styles.textColorPrimary]}>
          {translations('auth_screen_not_seeing_organization')}
        </Text>
        <TouchableOpacity onPress={submitLogout}>
          <Text style={[Typography.P3, styles.textColorPrimary]}>
            {translations('auth_screen_different_email')}
          </Text>
        </TouchableOpacity>
      </View>
      {/*<View style={styles.organizationDiv}>*/}
      {/*  <Text style={Typography.H4}>Create a new organization</Text>*/}
      {/*  <Text style={[Typography.P3, styles.textColorPrimary]}>*/}
      {/*    These are organizations you have access to*/}
      {/*  </Text>*/}
      {/*  <TextInput*/}
      {/*    mode="outlined"*/}
      {/*    placeholder="Name your organization"*/}
      {/*    style={styles.nameColor}*/}
      {/*    theme={{colors: {primary: Colors.BLUE_RIBBON}}}*/}
      {/*  />*/}
      {/*  <HelperText type="info" visible={true} style={styles.marginBottom}>*/}
      {/*    New organization name*/}
      {/*  </HelperText>*/}
      {/*  <RHButton*/}
      {/*    mode={'contained'}*/}
      {/*    onPress={createOrganization}*/}
      {/*    secondary*/}
      {/*    style={styles.button}>*/}
      {/*    CREATE A NEW ORGANIZATION*/}
      {/*  </RHButton>*/}
      {/*</View>*/}
    </BaseAuthScreen>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({database}) => ({
    instances: database.get('instances').query(),
  })),
)(OrganizationSelectionScreen);
