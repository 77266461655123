import {
  DurationTable,
  FrequencyTable,
  IntervalTable,
  RateTable,
  TaskAnalysisTable,
} from 'src/modules/session/components';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Text, TouchableOpacity, View, Animated, Platform} from 'react-native';
import ViewShot from 'react-native-view-shot';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {ProgramMethodAvatar} from 'src/modules/programs/components';
import {IconButton, Snackbar} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import Collapsible from 'react-native-collapsible';
import {ProgramProfile} from 'src/modules';
import {useNavigation} from '@react-navigation/core';
import {collectionMethods} from 'src/modules/programs/components/program-card/collection';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import _ from 'lodash';
import {Target} from 'src/models';
import moment from 'moment';
import {of} from 'rxjs';
import {intervalTypes} from 'src/hook-form-inputs/interval-type';
import {
  DurationProgressGraph,
  FrequencyProgressGraph,
  IntervalProgressGraph,
  TaskAnalysisProgressGraph,
  TrialByTrialProgress,
  RateProgressGraph,
} from 'src/modules/reports/components';
import {Menu, Modal} from 'src/design-system';
import {
  exportDuration,
  exportFrequency,
  exportInterval,
  exportRate,
  exportTaskAnalysis,
  exportTrialByTrial,
} from 'src/common-utils/program-evaluation';
import {exportCsv} from 'src/common-utils/export-csv';
import {exportGraph} from 'src/common-utils/export-graph';
import ProgramMenu from 'src/design-system/program-menu';
import ProgramInstructionModal from '../program-instruction-modal';
import ProgramProgress from 'src/modules/programs/components/program-progress-legacy';
import {useStyle} from 'src/providers/style';
import {analyzePrograms} from 'src/common-utils/analyzePrograms';

const SessionProgramDataTable = ({
  program,
  patient,
  sets,
  targets,
  totalEvents,
  envs,
  session,
  showGraphs = true,
  recentEvents,
  recentSets,
  programSessions,
  showProgramMenu = false,
  reorderPrograms = () => {},
}: any) => {
  const styles = useStyle();
  const database = useDatabase();
  const navigation = useNavigation();

  const graphExportRef = useRef();
  const [animatedValue] = useState(new Animated.Value(0));
  const recentSet = recentSets[0];
  const recentEvent = recentEvents[0];

  const handleAnimation = useCallback(() => {
    Animated.timing(animatedValue, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(animatedValue, {
        toValue: 0,
        duration: 5000,
        useNativeDriver: false,
      }).start();
    });
  }, [animatedValue]);

  const [latestEventId, setLatestEventId] = useState('');
  const [latestSetId, setLatestSetId] = useState('');
  const [showExportDlg, setShowExportDlg] = useState<string | undefined>(
    undefined,
  );
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);

  const viewHistory = () => {
    setHistoryModal(!historyModal);
  };
  const viewInstructions = () => {
    setInstructionsModal(!instructionsModal);
  };

  useEffect(() => {
    if (recentEvent) {
      const eventDurationInSeconds = moment(new Date()).diff(
        recentEvent.updatedAt,
        'seconds',
      );
      if (eventDurationInSeconds <= 20 && recentEvent.id !== latestEventId) {
        setLatestEventId(recentEvent.id);
        handleAnimation();
        reorderPrograms(program);
      }
    }
    if (recentSet) {
      const setDurationInSeconds = moment(new Date()).diff(
        recentSet.updatedAt,
        'seconds',
      );
      if (setDurationInSeconds <= 20 && recentSet.id !== latestSetId) {
        setLatestSetId(recentSet.id);
        handleAnimation();
        reorderPrograms(program);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentEvent, recentSet]);

  const borderInterpolation = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [Colors.RAVEN_WHITE, Colors.SECONDARY_600],
  });

  const animatedStyle = {
    shadowColor: borderInterpolation,
    borderColor: borderInterpolation,
    shadowOffset: {
      height: animatedValue,
      width: animatedValue,
    },
    shadowOpacity: animatedValue,
    borderWidth: 2,
  };

  const [collapsed, setCollapsed] = useState(Platform.OS !== 'web');
  const [currentTarget, setCurrentTarget] = useState(targets?.[0]?.id);

  const method = collectionMethods.find(
    collectionMethod => collectionMethod.value === program?.method,
  );

  const analyzedProgram = useMemo(() => {
    return analyzePrograms(
      program,
      sets,
      totalEvents,
      programSessions,
      true,
      session,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportPDF = async () => {
    await exportGraph(graphExportRef, program?.name, 'pdf');
    setShowExportDlg(`${program?.name}.pdf`);
  };

  const exportJpeg = async () => {
    await exportGraph(graphExportRef, program?.name, 'jpg');
    setShowExportDlg(`${program?.name}.jpg`);
  };

  const exportPng = async () => {
    await exportGraph(graphExportRef, program?.name, 'png');
    setShowExportDlg(`${program?.name}.png`);
  };

  const createCSVFile = async () => {
    let data: any[] = [];
    switch (program.method) {
      case 'duration':
        data = await exportDuration(database, sets);
        break;
      case 'frequency':
        data = await exportFrequency(database, totalEvents);
        break;
      case 'interval':
        data = await exportInterval(database, sets);
        break;
      case 'rate':
        data = await exportRate(database, sets, totalEvents);
        break;
      case 'trial_by_trial':
        data = await exportTrialByTrial(
          database,
          sets,
          currentTarget,
          program?.numberOfTrials,
        );
        break;
      case 'task_analysis':
        data = await exportTaskAnalysis(database, sets, targets);
        break;
    }

    await exportCsv(
      `${patient?.lastName}_${program?.name}_${moment().format('YYYY-MM-DD')}`,
      data,
    );
  };

  const options = [
    {
      onPress: exportPDF,
      title: 'PDF',
      icon: 'file-pdf-box',
    },
    {
      onPress: createCSVFile,
      title: 'CSV',
      icon: 'file-excel',
    },
    {
      onPress: exportJpeg,
      title: 'JPEG',
      icon: 'file-jpg-box',
    },
    {
      onPress: exportPng,
      title: 'PNG',
      icon: 'file-png-box',
    },
  ];

  return (
    <Animated.View
      style={[
        styles.backgroundColorWhite,
        styles.marginBottom,
        styles.borderRadius,
        animatedStyle,
      ]}>
      <TouchableOpacity
        style={[
          styles.flex,
          styles.row,
          styles.justifySpaceBetween,
          styles.alignCenter,
          styles.padding,
        ]}
        onPress={() => setCollapsed(!collapsed)}>
        <View style={[styles.flex, styles.row]}>
          <View style={[styles.marginLRight]}>
            <ProgramMethodAvatar type={program?.method} />
          </View>
          <View style={[styles.flex, styles.column]}>
            <Text style={[Typography.P3_MEDIUM, styles.textColorPrimary]}>
              {program?.name}
            </Text>
            <Text style={[Typography.CAPTION, styles.textColorSecondary]}>
              {program?.type === 'behavior'
                ? 'Behavior Tracker'
                : 'Skill Acquisition'}{' '}
              |{' '}
              {program?.method === 'interval'
                ? intervalTypes?.find(item => item.value === program?.interval)
                    ?.label
                : method?.title}
            </Text>
          </View>
        </View>
        <View style={[styles.row, styles.alignCenter]}>
          {showProgramMenu ? (
            <>
              <ProgramMenu
                viewInstructions={viewInstructions}
                viewHistory={viewHistory}
              />
              {instructionsModal ? (
                <ProgramInstructionModal
                  show={[instructionsModal, setInstructionsModal]}
                  program={program}
                />
              ) : null}
              {historyModal ? (
                <Modal
                  show={[historyModal, setHistoryModal]}
                  title={'Progress'}>
                  <ProgramProgress program={program} />
                </Modal>
              ) : null}
            </>
          ) : (
            <></>
          )}
          <Menu
            anchor={<AntDesign size={20} name="download" />}
            options={options}
          />
          {showGraphs ? (
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={'trending-up'}
              onPress={() =>
                navigation.navigate(ProgramProfile.screenName, {
                  programId: program.id,
                  screen: 'Progress',
                })
              }
            />
          ) : (
            <></>
          )}
          <IconButton
            color={Colors.RAVEN_BLACK}
            icon={!collapsed ? 'chevron-up' : 'chevron-down'}
            onPress={() => setCollapsed(!collapsed)}
          />
        </View>
      </TouchableOpacity>
      <Collapsible
        collapsed={collapsed}
        style={[styles.paddingHorizontal, styles.paddingBottom]}
        renderChildrenCollapsed={!collapsed}>
        <ViewShot ref={graphExportRef}>
          {program?.method === 'task_analysis' ? (
            <>
              {showGraphs ? (
                <TaskAnalysisProgressGraph
                  program={analyzedProgram}
                  sessions={[session]}
                  envs={envs}
                />
              ) : (
                <></>
              )}
              <View style={[styles.paddingTop]}>
                <TaskAnalysisTable
                  program={program}
                  sets={recentSets}
                  targets={targets}
                />
              </View>
            </>
          ) : program?.method === 'trial_by_trial' ? (
            <>
              {program?.unit !== 'collection' ? (
                <TrialByTrialProgress
                  program={program}
                  sets={sets}
                  events={totalEvents}
                  recentSets={recentSets}
                  recentEvents={recentEvents}
                  sessions={programSessions}
                  targets={targets}
                  currentTarget={currentTarget}
                  setCurrentTarget={setCurrentTarget}
                  table={true}
                  showGraphs={showGraphs}
                  envs={envs}
                  sessionMessage={true}
                />
              ) : (
                <TaskAnalysisProgressGraph
                  program={analyzedProgram}
                  sessions={[session]}
                  envs={envs}
                />
              )}
            </>
          ) : program?.method === 'rate' ? (
            <>
              {showGraphs ? (
                <RateProgressGraph
                  program={analyzedProgram}
                  sessions={[session]}
                  sets={recentSets}
                  envs={envs}
                  events={recentEvents}
                />
              ) : (
                <></>
              )}
              <View style={[styles.paddingTop]}>
                <RateTable program={program} sets={recentSets} />
              </View>
            </>
          ) : program?.method === 'frequency' ? (
            <>
              {showGraphs ? (
                <FrequencyProgressGraph
                  program={analyzedProgram}
                  sessions={[session]}
                  envs={envs}
                />
              ) : (
                <></>
              )}
              <View style={[styles.paddingTop]}>
                <FrequencyTable program={program} sets={recentSets} />
              </View>
            </>
          ) : program?.method === 'duration' ? (
            <>
              {showGraphs ? (
                <DurationProgressGraph
                  program={analyzedProgram}
                  sessions={[session]}
                  sets={recentSets}
                  envs={envs}
                />
              ) : (
                <></>
              )}
              <View style={[styles.paddingTop]}>
                <DurationTable program={program} sets={recentSets} />
              </View>
            </>
          ) : program?.method === 'interval' ? (
            <>
              {showGraphs ? (
                <IntervalProgressGraph
                  program={analyzedProgram}
                  sessions={[session]}
                  envs={envs}
                  sets={recentSets}
                  events={recentEvents}
                  graph={'bar'}
                />
              ) : (
                <></>
              )}
              <View style={[styles.paddingTop]}>
                <IntervalTable
                  program={program}
                  sets={recentSets}
                  events={recentEvents}
                />
              </View>
            </>
          ) : (
            <></>
          )}
        </ViewShot>
      </Collapsible>
      <Snackbar
        visible={showExportDlg !== undefined}
        onDismiss={() => setShowExportDlg(undefined)}
        duration={3000}
        action={{
          label: 'Dismiss',
          onPress: () => {},
        }}>
        {showExportDlg} was downloaded.
      </Snackbar>
    </Animated.View>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({session, program, database}: any) => {
    const queries = [];
    if (program.type === 'skill') {
      queries.push(Q.where('end_timestamp', Q.notEq(null)));
    }
    return {
      sets: database
        .get('sets')
        .query(
          Q.and(
            Q.where('program_id', program?.id),
            Q.where('deleted_at', null),
            ...queries,
          ),
          Q.sortBy('created_at', Q.asc),
        ),
      recentSets: database
        .get('sets')
        .query(
          Q.and(
            Q.where('session_id', session.id),
            Q.where('program_id', program.id),
            Q.where('deleted_at', null),
          ),
          Q.sortBy('updated_at', Q.asc),
        ),
      patient: program.patient,
    };
  }),
  withObservables(
    ['sets'],
    ({patient, sets, recentSets, program, database}: any) => {
      const setIds = _.map(sets, 'id');
      const recentSetIds = _.map(recentSets, 'id');
      const targetIds = sets.map(set => set.targetId);
      const sessionIds = _.map(sets, 'sessionId');
      return {
        targets:
          program.method === 'trial_by_trial'
            ? database
                .get(Target.table)
                .query(Q.where('id', Q.oneOf(targetIds)))
            : program?.enabledTargets,
        totalEvents: database
          .get('events')
          .query(
            Q.where('set_id', Q.oneOf(setIds)),
            Q.where('deleted_at', null),
            Q.sortBy('created_at', Q.asc),
          ),
        recentEvents: database
          .get('events')
          .query(
            Q.where('set_id', Q.oneOf(recentSetIds)),
            Q.where('deleted_at', null),
            Q.sortBy('updated_at', Q.desc),
          ),
        envs: patient?.activeEnvironmentalFactors ?? of([]),
        programSessions: database
          .get('sessions')
          .query(Q.where('id', Q.oneOf(sessionIds)), Q.sortBy('date', Q.asc)),
      };
    },
  ),
)(SessionProgramDataTable);
