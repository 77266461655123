import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';
import {RRule, RRuleSet, rrulestr, Weekday} from 'rrule';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import AppointmentForm from 'src/modules/scheduling/components/appointment-form';
import {BaseScreen} from 'src/design-system';
import {ScrollView, View, Text} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import {
  Appointment,
  NoteTemplateVersion,
  Participant,
  Session,
} from 'src/models';
import {useSelector} from 'react-redux';
import EditRecurringAppointment from 'src/modules/scheduling/components/edit-recurring-appointment';
import {Modal} from 'src/design-system';
import {Q} from '@nozbe/watermelondb';

const roundedUp = Math.ceil(moment().minute() / 15) * 15;

const ScheduleAppointment = ({
  profile,
  appointment,
  staff,
  patients,
  route,
  navigation,
}: any) => {
  const {
    id = null,
    date = null,
    start = null,
    patient = null,
    location = null,
  }: any = route?.params || {};

  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();
  const [errors, setErrors] = useState<any | boolean>(false);
  const [editRecurringAppointment, setEditRecurringAppointment] = useState<
    boolean | any
  >(false);

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const title = id
    ? translations('edit_appointment')
    : translations('create_appointment');
  const currentRruleSet = !_.isEmpty(appointment.rrule)
    ? rrulestr(appointment.rrule, {
        forceset: true,
      })
    : new RRuleSet();
  const currentRrule =
    currentRruleSet.rrules().length > 0
      ? currentRruleSet.rrules()[currentRruleSet.rrules().length - 1]
      : new RRule();
  const initialValues = {
    clients: patient
      ? [patient]
      : patients.map((entity: any) => entity.patient.id),
    staff: staff.map((entity: any) => entity.user.id),
    selectedStaff: staff.map((entity: any) => ({
      billable: entity.billable ? entity.billable : true,
      supervision: entity.supervision ? entity.supervision : false,
      id: entity.user.id,
      startTime: entity.startTime,
      endTime: entity.endTime,
      cpt: entity.cpt,
    })),
    noteTemplate: appointment.noteTemplateId || '',
    location: location || appointment.location,
    date: date ? moment(date).toDate() : appointment.date,
    startTime: start
      ? moment(start).toDate()
      : moment(appointment?.start).set({second: 0}).toDate(),
    endTime: start
      ? moment(start).add(30, 'm').toDate()
      : moment(appointment?.end).set({second: 0}).toDate(),
    repeat: !_.isEmpty(appointment.rrule),
    repeatByWeekDay: !_.isEmpty(appointment.rrule)
      ? currentRrule.origOptions.byweekday
      : [RRule.FR],
    repeatFreq: !_.isEmpty(appointment.rrule)
      ? currentRrule.origOptions.freq?.toString()
      : RRule.WEEKLY.toString(),
    repeatInterval: !_.isEmpty(appointment.rrule)
      ? currentRrule.origOptions.interval?.toString()
      : '1',
    repeatEndMode: !_.isEmpty(appointment.rrule)
      ? currentRrule.origOptions.until
        ? 'on'
        : currentRrule.origOptions.count
        ? 'after'
        : 'never'
      : 'never',
    repeatOccurrences: !_.isEmpty(appointment.rrule)
      ? currentRrule.origOptions.count?.toString()
      : '10',
    repeatUntil: !_.isEmpty(appointment.rrule)
      ? currentRrule.origOptions.until
      : new Date(),
    address: appointment.address,
    customAddress: appointment.customAddress,
  };

  const validationSchema = Yup.object({
    clients: Yup.array()
      .min(1, 'At least one Client is required.')
      .required('Client selection is required'),
    staff: Yup.array()
      .min(1, 'At least one Staff member is required.')
      .required('Staff selection is required'),
    location: Yup.string().required('Place of Service is required'),
    noteTemplate: Yup.string().required('Note Template is required.'),
    date: Yup.string().when(['repeat', 'repeatFreq'], {
      is: (repeat: boolean, repeatFreq: any) => {
        return !repeat || (repeat && repeatFreq === RRule.MONTHLY.toString());
      },
      then: () => Yup.string().required('Date is required.'),
    }),
    repeatByWeekDay: Yup.array().when(['repeat', 'repeatFreq'], {
      is: (repeat: boolean, repeatFreq: any) => {
        return repeat && repeatFreq.toString() === RRule.WEEKLY.toString();
      },
      then: () =>
        Yup.array()
          .of(Yup.string().required())
          .min(1, 'Repeat on is required.'),
    }),
    startTime: Yup.date().required('Start Time is required.'),
    endTime: Yup.date()
      .required('End Time is required.')
      .test('after', 'End Time must be after Start Time.', function (value) {
        const appStart = this.parent.startTime;
        const startDate = new Date(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          appStart.getHours(),
          appStart.getMinutes(),
        );
        const endDate = new Date(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          value.getHours(),
          value.getMinutes(),
        );
        return moment(endDate).isSameOrAfter(moment(startDate));
      }),
    selectedStaff: Yup.array()
      .of(
        Yup.object().shape({
          startTime: Yup.date()
            .test(
              'is-within-appointment-start',
              'Staff Start Time must be within Appointment times.',
              function (value) {
                const valueDate = moment(
                  moment(value).format('HH:mm:ss'),
                  'HH:mm:ss',
                );
                const startDate = moment(
                  moment(startTime).format('HH:mm:ss'),
                  'HH:mm:ss',
                );
                const endDate = moment(
                  moment(endTime).format('HH:mm:ss'),
                  'HH:mm:ss',
                );

                return (
                  valueDate.isSameOrAfter(startDate, 'minutes') &&
                  valueDate.isSameOrBefore(endDate, 'minutes')
                );
              },
            )
            .required('Staff Start Time is required.'),
          endTime: Yup.date()
            .test(
              'is-after-start-time',
              'Staff End Time must be after Staff Start Time.',
              function (value) {
                const valueDate = moment(
                  moment(value).format('HH:mm:ss'),
                  'HH:mm:ss',
                );
                const startDate = moment(
                  moment(startTime).format('HH:mm:ss'),
                  'HH:mm:ss',
                );

                return valueDate.isSameOrAfter(startDate, 'minutes');
              },
            )
            .test(
              'is-within-appointment-end',
              'Staff End Time must be within Appointment times.',
              function (value) {
                const valueDate = moment(
                  moment(value).format('HH:mm:ss'),
                  'HH:mm:ss',
                );
                const startDate = moment(
                  moment(startTime).format('HH:mm:ss'),
                  'HH:mm:ss',
                );
                const endDate = moment(
                  moment(endTime).format('HH:mm:ss'),
                  'HH:mm:ss',
                );

                return (
                  valueDate.isSameOrAfter(startDate, 'minutes') &&
                  valueDate.isSameOrBefore(endDate, 'minutes')
                );
              },
            )
            .required('Staff End Time is required.'),
          supervision: Yup.boolean().test(
            'supervision',
            'Supervision appointments should have at least one non-supervisor for data collection.',
            () => {
              const selectedStaff = [...methods.getValues().selectedStaff];
              return selectedStaff?.some(value => !value.supervision);
            },
          ),
        }),
      )
      .required('Staff information is required'),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const users = useWatch({control: methods.control, name: 'staff'});
  const clients = useWatch({control: methods.control, name: 'clients'});
  const repeat = useWatch({control: methods.control, name: 'repeat'});
  const repeatFreq = useWatch({control: methods.control, name: 'repeatFreq'});
  const ends = useWatch({control: methods.control, name: 'repeatEndMode'});
  const startTime = useWatch({control: methods.control, name: 'startTime'});
  const endTime = useWatch({control: methods.control, name: 'endTime'});
  const navigateBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Schedule');
    }
  };

  const handleError = (err: any) => {
    setErrors(err);
  };

  const handleSubmit = async (values: any) => {
    if (id) {
      if (appointment.rrule) {
        setEditRecurringAppointment(values);
      } else {
        let rrule = '';
        if (values.repeat) {
          const freq = parseInt(values.repeatFreq, 10);
          const interval = parseInt(values.repeatInterval, 10);
          const byweekday =
            freq === RRule.WEEKLY
              ? values.repeatByWeekDay.map((weekDay: string) =>
                  Weekday.fromStr(weekDay),
                )
              : [];
          const until =
            values.repeatEndMode === 'on'
              ? values.repeatUntil || new Date()
              : null;
          const count =
            values.repeatEndMode === 'after'
              ? parseInt(values.repeatOccurrences, 10)
              : null;

          const newRruleSet = new RRuleSet();
          for (const exrule of currentRruleSet.exrules()) {
            newRruleSet.exrule(exrule);
          }
          for (const rdate of currentRruleSet.rdates()) {
            newRruleSet.rdate(rdate);
          }
          for (const exdate of currentRruleSet.exdates()) {
            newRruleSet.exdate(exdate);
          }
          const startDate = new Date(values.date);
          const rule = new RRule({
            freq,
            interval,
            byweekday,
            dtstart: new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate(),
              0,
              0,
              0,
              0,
            ),
            until,
            count,
          });
          newRruleSet.rrule(rule);
          rrule = newRruleSet.toString();
        }
        await appointment.updateEntity({
          location: values.location,
          date: values.date,
          start: values.startTime,
          end: values.endTime,
          rrule: rrule,
          noteTemplateId: values.noteTemplate,
          address: values.address,
          customAddress: values.customAddress,
        });
        const appointmentPatients =
          await appointment.patientParticipants.fetch();
        const appointmentStaff = await appointment.staffParticipants.fetch();
        const differencePatients = _.differenceWith(
          values.clients,
          appointmentPatients,
          (value1, value2) => {
            return value1 === value2.patient.id;
          },
        );
        const differenceStaff = _.differenceWith(
          values.selectedStaff,
          appointmentStaff,
          (value1, value2) => {
            return value1.id === value2.user.id;
          },
        );
        const deletePatients = _.differenceWith(
          appointmentPatients,
          values.clients,
          (value1, value2) => {
            return value1.patient.id === value2;
          },
        );
        const deleteStaff = _.differenceWith(
          appointmentStaff,
          values.selectedStaff,
          (value1, value2) => {
            return value1.user.id === value2.id;
          },
        );

        for (const patientParticipant of deletePatients) {
          await database.write(async () => {
            patientParticipant.delete();
          });
        }
        for (const patientParticipant of differencePatients) {
          await database.write(async () => {
            await database.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = appointment.id;
              entity.patient.id = patientParticipant;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
        for (const value1 of appointmentStaff) {
          const index = values.selectedStaff.findIndex(
            (value2: any) => value1.user.id === value2.id,
          );
          if (index !== -1) {
            await value1.updateEntity({
              billable: values?.selectedStaff[index].billable,
              supervision: values?.selectedStaff[index].supervision,
              startTime: values?.selectedStaff[index].startTime,
              endTime: values?.selectedStaff[index].endTime,
              cpt: values?.selectedStaff[index].cpt,
            });
          }
        }
        for (const staffParticipant of deleteStaff) {
          staffParticipant.delete();
        }
        for (const staffParticipant of differenceStaff) {
          await database.write(async () => {
            await database.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.billable = staffParticipant.billable;
              entity.supervision = staffParticipant.supervision;
              entity.appointment.id = appointment.id;
              entity.user.id = staffParticipant.id;
              entity.startTime = staffParticipant.startTime;
              entity.endTime = staffParticipant.endTime;
              entity.cpt = staffParticipant.cpt;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
        navigateBack();
      }
    } else {
      let rrule = '';
      if (values.repeat) {
        const freq = parseInt(values.repeatFreq, 10);
        const interval = parseInt(values.repeatInterval, 10);
        const byweekday =
          freq === RRule.WEEKLY
            ? values.repeatByWeekDay.map((weekDay: string) =>
                Weekday.fromStr(weekDay),
              )
            : [];
        const until =
          values.repeatEndMode === 'on'
            ? values.repeatUntil || new Date()
            : null;
        const count =
          values.repeatEndMode === 'after'
            ? parseInt(values.repeatOccurrences, 10)
            : null;

        const newRruleSet = new RRuleSet();

        const startDate = new Date(values.date);

        const rule = new RRule({
          freq,
          interval,
          byweekday,
          dtstart: new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            0,
            0,
            0,
            0,
          ),
          until,
          count,
        });
        newRruleSet.rrule(rule);
        rrule = newRruleSet.toString();
      }
      await database?.write(async () => {
        const createdAppointment = await database
          ?.get(Appointment.table)
          .create(entity => {
            entity.partition = selectedGroup;
            entity.location = values.location;
            entity.date = values.date;
            entity.start = values.startTime;
            entity.end = values.endTime;
            entity.rrule = rrule;
            entity.noteTemplateId = values.noteTemplate;
            entity.address = values.address;
            entity.customAddress = values.customAddress;
            entity.createdBy = profile.id;
            entity.updatedBy = profile.id;
          });
        for (const staffParticipant of values.selectedStaff) {
          await database?.get(Participant.table).create(entity => {
            entity.partition = selectedGroup;
            entity.billable = staffParticipant.billable;
            entity.supervision = staffParticipant.supervision;
            entity.appointment.id = createdAppointment.id;
            entity.user.id = staffParticipant.id;
            entity.startTime =
              values.selectedStaff.length === 1
                ? values.startTime
                : staffParticipant.startTime;
            entity.endTime =
              values.selectedStaff.length === 1
                ? values.endTime
                : staffParticipant.endTime;
            entity.cpt = staffParticipant.cpt;
          });
        }
        for (const client of values.clients) {
          await database?.get(Participant.table).create(entity => {
            entity.partition = selectedGroup;
            entity.appointment.id = createdAppointment.id;
            entity.patient.id = client;
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
        }
        let noteTemplate: any;
        const noteTemplateVersions = await database
          .get(NoteTemplateVersion.table)
          .query(
            Q.where('note_template_id', values.noteTemplate),
            Q.sortBy('published_at', 'desc'),
            Q.take(1),
          );
        if (noteTemplateVersions.length) {
          noteTemplate = noteTemplateVersions[0];
        }

        await database?.get(Session.table).create(entity => {
          entity.partition = selectedGroup;
          entity.type = 'session';
          entity.date = values.date;
          entity.sessionDate = moment(values.date).format('YYYY-MM-DD');
          entity.startTimestamp = null;
          entity.endTimestamp = null;
          entity.editedStartTimestamp = null;
          entity.editedEndTimestamp = null;
          entity.note = noteTemplate.template;
          entity.noteTemplateId = values.noteTemplate;
          entity.noteTemplateVersionId = noteTemplate.id;
          entity.address = values.address;
          entity.customAddress = values.customAddress;
          entity.therapistSignature = null;
          entity.therapistSignature2 = null;
          entity.parentSignature1 = null;
          entity.parentSignature2 = null;
          entity.attestationTimestamp = null;
          entity.submissionTimestamp = null;
          entity.appointment.id = createdAppointment.id;
          entity.patient.id = values.clients[0];
          entity.user.id = null;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
      navigateBack();
    }
  };
  const onSubmit = async (values: any) => {
    const rruleSet = rrulestr(appointment.rrule, {
      forceset: true,
    });

    const todaysDate = new Date(editRecurringAppointment.date);

    switch (values.action) {
      case 'event':
        rruleSet.exdate(
          new Date(
            todaysDate.getFullYear(),
            todaysDate.getMonth(),
            todaysDate.getDate(),
            0,
            0,
            0,
            0,
          ),
        );
        await appointment.updateEntity({
          rrule: rruleSet.toString(),
        });
        await database?.write(async () => {
          const createAppointment = await database
            ?.get(Appointment.table)
            .create(entity => {
              entity.partition = selectedGroup;
              entity.location = editRecurringAppointment.location;
              entity.date = todaysDate;
              entity.start = editRecurringAppointment.startTime;
              entity.end = editRecurringAppointment.endTime;
              entity.noteTemplateId = editRecurringAppointment.noteTemplate;
              entity.address = editRecurringAppointment.address;
              entity.customAddress = editRecurringAppointment.customAddress;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          for (const participant of editRecurringAppointment.selectedStaff) {
            await database?.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = createAppointment.id;
              entity.userId = participant.id;
              entity.billable = participant.billable;
              entity.supervision = participant.supervision;
              entity.startTime = new Date(participant.startTime.getTime());
              entity.endTime = new Date(participant.endTime.getTime());
              entity.cpt = participant.cpt;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          for (const participant of editRecurringAppointment.clients) {
            await database?.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = createAppointment.id;
              entity.patientId = participant;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
        });
        break;
      case 'following':
        const ruleSet = new RRuleSet();
        for (const exrule of rruleSet.exrules()) {
          ruleSet.exrule(exrule);
        }
        for (const rdate of rruleSet.rdates()) {
          ruleSet.rdate(rdate);
        }
        for (const exdate of rruleSet.exdates()) {
          ruleSet.exdate(exdate);
        }

        ruleSet.rrule(
          new RRule({
            ...rruleSet.rrules()[0].options,
            until: new Date(
              todaysDate.getFullYear(),
              todaysDate.getMonth(),
              todaysDate.getDate() - 1,
              22,
              59,
            ).setMinutes(moment(rruleSet._dtstart).utcOffset()),
          }),
        );

        await appointment.updateEntity({
          rrule: ruleSet.toString(),
        });

        let rruleString = '';
        if (editRecurringAppointment.repeat) {
          const rrule = new RRuleSet();
          for (const exrule of rruleSet.exrules()) {
            rrule.exrule(exrule);
          }
          for (const rdate of rruleSet.rdates()) {
            rrule.rdate(rdate);
          }
          for (const exdate of rruleSet.exdates()) {
            rrule.exdate(exdate);
          }

          const freq = parseInt(editRecurringAppointment.repeatFreq, 10);
          const interval = parseInt(
            editRecurringAppointment.repeatInterval,
            10,
          );
          const byweekday =
            freq === RRule.WEEKLY
              ? editRecurringAppointment.repeatByWeekDay.map(
                  (weekDay: string) => Weekday.fromStr(weekDay),
                )
              : [];
          const until =
            editRecurringAppointment.repeatEndMode === 'on'
              ? editRecurringAppointment.repeatUntil || new Date()
              : null;
          const count =
            editRecurringAppointment.repeatEndMode === 'after'
              ? parseInt(editRecurringAppointment.repeatOccurrences, 10)
              : null;
          const rule = new RRule({
            freq,
            interval,
            byweekday,
            dtstart: new Date(
              todaysDate.getFullYear(),
              todaysDate.getMonth(),
              todaysDate.getDate(),
              0,
              0,
              0,
              0,
            ),
            until,
            count,
          });
          rrule.rrule(rule);
          rruleString = rrule.toString();
        }

        await database?.write(async () => {
          const createAppointment = await database
            ?.get(Appointment.table)
            .create(entity => {
              entity.partition = selectedGroup;
              entity.location = editRecurringAppointment.location;
              entity.date = todaysDate;
              entity.start = editRecurringAppointment.startTime;
              entity.end = editRecurringAppointment.endTime;
              entity.noteTemplateId = editRecurringAppointment.noteTemplate;
              entity.address = editRecurringAppointment.address;
              entity.customAddress = editRecurringAppointment.customAddress;
              entity.rrule = rruleString;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          for (const participant of editRecurringAppointment.selectedStaff) {
            await database?.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = createAppointment.id;
              entity.userId = participant.id;
              entity.billable = participant.billable;
              entity.supervision = participant.supervision;
              entity.startTime = new Date(participant.startTime.getTime());
              entity.endTime = new Date(participant.endTime.getTime());
              entity.cpt = participant.cpt;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          for (const participant of editRecurringAppointment.clients) {
            await database?.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = createAppointment.id;
              entity.patientId = participant;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
        });
        break;
      case 'all':
        let rrule = '';
        if (editRecurringAppointment.repeat) {
          const freq = parseInt(editRecurringAppointment.repeatFreq, 10);
          const interval = parseInt(
            editRecurringAppointment.repeatInterval,
            10,
          );
          const byweekday =
            freq === RRule.WEEKLY
              ? editRecurringAppointment.repeatByWeekDay.map(
                  (weekDay: string) => Weekday.fromStr(weekDay),
                )
              : [];
          const until =
            editRecurringAppointment.repeatEndMode === 'on'
              ? editRecurringAppointment.repeatUntil || new Date()
              : null;
          const count =
            editRecurringAppointment.repeatEndMode === 'after'
              ? parseInt(editRecurringAppointment.repeatOccurrences, 10)
              : null;

          const newRruleSet = new RRuleSet();
          for (const exrule of currentRruleSet.exrules()) {
            newRruleSet.exrule(exrule);
          }
          for (const rdate of currentRruleSet.rdates()) {
            newRruleSet.rdate(rdate);
          }
          for (const exdate of currentRruleSet.exdates()) {
            newRruleSet.exdate(exdate);
          }
          const startDate = new Date(appointment.date);
          const rule = new RRule({
            freq,
            interval,
            byweekday,
            dtstart: new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate(),
              0,
              0,
              0,
              0,
            ),
            until,
            count,
          });
          newRruleSet.rrule(rule);
          rrule = newRruleSet.toString();
        }
        await appointment.updateEntity({
          location: editRecurringAppointment.location,
          start: editRecurringAppointment.startTime,
          end: editRecurringAppointment.endTime,
          rrule: rrule,
          noteTemplateId: editRecurringAppointment.noteTemplate,
          address: editRecurringAppointment.address,
          customAddress: editRecurringAppointment.customAddress,
        });
        const appointmentPatients =
          await appointment.patientParticipants.fetch();
        const appointmentStaff = await appointment.staffParticipants.fetch();
        const differencePatients = _.differenceWith(
          editRecurringAppointment.clients,
          appointmentPatients,
          (value1, value2) => {
            return value1 === value2.patient.id;
          },
        );
        const differenceStaff = _.differenceWith(
          editRecurringAppointment.selectedStaff,
          appointmentStaff,
          (value1, value2) => {
            return value1.id === value2.user.id;
          },
        );
        const deletePatients = _.differenceWith(
          appointmentPatients,
          editRecurringAppointment.clients,
          (value1, value2) => {
            return value1.patient.id === value2;
          },
        );
        const deleteStaff = _.differenceWith(
          appointmentStaff,
          editRecurringAppointment.selectedStaff,
          (value1, value2) => {
            return value1.user.id === value2.id;
          },
        );

        for (const patientParticipant of deletePatients) {
          await database.write(async () => {
            patientParticipant.delete();
          });
        }
        for (const patientParticipant of differencePatients) {
          await database.write(async () => {
            await database.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = appointment.id;
              entity.patient.id = patientParticipant;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
        for (const value1 of appointmentStaff) {
          const index = editRecurringAppointment.selectedStaff.findIndex(
            (value2: any) => value1.user.id === value2.id,
          );
          if (index !== -1) {
            await value1.updateEntity({
              billable: editRecurringAppointment?.selectedStaff[index].billable,
              supervision:
                editRecurringAppointment?.selectedStaff[index].supervision,
              startTime:
                editRecurringAppointment?.selectedStaff[index].startTime,
              endTime: editRecurringAppointment?.selectedStaff[index].endTime,
              cpt: editRecurringAppointment?.selectedStaff[index].cpt,
            });
          }
        }
        for (const staffParticipant of deleteStaff) {
          staffParticipant.delete();
        }
        for (const staffParticipant of differenceStaff) {
          await database.write(async () => {
            await database.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.billable = staffParticipant.billable;
              entity.supervision = staffParticipant.supervision;
              entity.appointment.id = appointment.id;
              entity.user.id = staffParticipant.id;
              entity.startTime = staffParticipant.startTime;
              entity.endTime = staffParticipant.endTime;
              entity.cpt = staffParticipant.cpt;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }
        break;
    }
    setEditRecurringAppointment(false);
    navigateBack();
  };

  useEffect(() => {
    if (users.length === 1) {
      const selectedStaff = [
        {
          ...methods.getValues().selectedStaff[0],
          startTime,
          endTime,
        },
      ];

      methods.setValue('selectedStaff', selectedStaff);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, startTime, endTime]);

  useEffect(() => {
    const selectedStaff = [...methods.getValues().selectedStaff];
    const deleteStaff = _.differenceWith(
      selectedStaff,
      users,
      (value1: any, value2: string) => {
        return value1.id === value2;
      },
    );
    const differenceStaff = _.differenceWith(
      users,
      selectedStaff,
      (value1: string, value2: any) => {
        return value1 === value2.id;
      },
    );
    for (const staffMember of deleteStaff) {
      const index = selectedStaff.findIndex(
        (member: any) => member.id === staffMember,
      );

      selectedStaff.splice(index, 1);
    }
    for (const staffMember of differenceStaff) {
      selectedStaff.push({
        id: staffMember,
        billable: true,
        supervision: false,
        startTime: methods.getValues().startTime,
        endTime: methods.getValues().endTime,
        cpt: [],
      });
    }
    methods.setValue('selectedStaff', selectedStaff);
  }, [methods, users]);
  return (
    <BaseScreen title={title}>
      <EditRecurringAppointment
        show={[editRecurringAppointment, setEditRecurringAppointment]}
        cancelled={() => setEditRecurringAppointment(false)}
        onSubmit={onSubmit}
      />
      <ScrollView
        style={[styles.flex]}
        automaticallyAdjustKeyboardInsets={true}>
        <FormProvider {...methods}>
          <AppointmentForm
            appointment={appointment}
            clients={clients}
            repeat={repeat}
            repeatFreq={repeatFreq}
            ends={ends}
            canEdit={!id}
          />
        </FormProvider>
      </ScrollView>
      <View
        style={[
          styles.width,
          styles.padding,
          styles.positionAbsolute,
          styles.backgroundColorWhite,
          styles.bottom0,
        ]}>
        <View style={[styles.justifySpaceBetween, styles.row]}>
          <RHButton
            secondary
            onPress={() => {
              navigateBack();
            }}
            textColor={Colors.RAVEN_BLACK}
            color="black"
            mode="outlined">
            {translations('cancel_button')}
          </RHButton>
          <RHButton
            mode="contained"
            onPress={() => methods.handleSubmit(handleSubmit, handleError)()}>
            {translations('save_button')}
          </RHButton>
        </View>
      </View>
      <Modal show={[errors, setErrors]} title={'Error'}>
        {Object.keys(errors).map(errorKey => {
          return (
            <View key={errorKey} style={[styles.paddingLBottom]}>
              <Text style={[Typography.P2, styles.paddingSMBottom]}>
                {translations(errorKey)}
              </Text>
              {errorKey === 'selectedStaff' ? (
                errors[errorKey].map((staffError, index) => (
                  <View key={index}>
                    {Object.keys(staffError).map(subErrorKey => (
                      <Text key={subErrorKey} style={[Typography.P3]}>
                        {staffError[subErrorKey].message}
                      </Text>
                    ))}
                  </View>
                ))
              ) : (
                <Text style={[Typography.P3]}>{errors[errorKey].message}</Text>
              )}
            </View>
          );
        })}
      </Modal>
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['route'], ({route, database}: any) => {
    if (route.params?.id) {
      return {
        appointment: database
          .get('appointments')
          .findAndObserve(route.params.id),
      };
    }
    return {
      appointment: of({
        location: '',
        date: new Date(),
        start: moment().minute(roundedUp).second(0).toDate(),
        end: moment().minute(roundedUp).second(0).add(30, 'minutes').toDate(),
      }),
    };
  }),
  withObservables(['appointment'], ({appointment}) => {
    if (!appointment?.id) {
      return {
        patients: of([]),
        staff: of([]),
      };
    }
    return {
      patients: appointment.patientParticipants,
      staff: appointment.staffParticipants,
    };
  }),
)(ScheduleAppointment);
