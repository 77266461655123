import {Q} from '@nozbe/watermelondb';
import {
  text,
  date,
  lazy,
  children,
  relation,
  writer,
  json,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
import {store} from 'src/redux/store';

export class Appointment extends Base {
  static table = 'appointments';
  static associations = {
    participants: {type: 'has_many', foreignKey: 'appointment_id'},
    sessions: {type: 'has_many', foreignKey: 'appointment_id'},
  };

  @relation('sessions', 'appointment_id') singleSession;

  @children('participants') participants;

  @lazy
  staff = this.collections
    .get('users')
    .query(
      Q.on(
        'participants',
        Q.and(Q.where('appointment_id', this.id), Q.where('deleted_at', null)),
      ),
      Q.where('deleted_at', null),
    );

  @lazy
  staffParticipants = this.collections.get('participants').query(
    Q.where('appointment_id', this.id),
    Q.or(
      Q.where('user_id', Q.notEq('')),
      // Q.where('user_id', Q.eq()),
    ),
    Q.where('deleted_at', null),
  );

  @lazy
  currentStaffParticipant = (userId: string) =>
    this.participants.extend(Q.where('user_id', userId));

  @lazy
  patients = this.collections
    .get('patients')
    .query(
      Q.on(
        'participants',
        Q.and(Q.where('appointment_id', this.id), Q.where('deleted_at', null)),
      ),
      Q.where('deleted_at', null),
    );

  @lazy
  patientParticipants = this.collections.get('participants').query(
    Q.where('appointment_id', this.id),
    Q.or(
      Q.where('patient_id', Q.notEq('')),
      // Q.where('patient_id', Q.eq()),
    ),
    Q.where('deleted_at', null),
  );

  @children('sessions') sessions;

  session = this.sessions.extend(
    Q.where('deleted_at', null),
    Q.sortBy('created_at', Q.desc),
  );

  @date('date') date;
  @date('start_timestamp') startTimestamp;
  @date('end_timestamp') endTimestamp;
  @date('start') start;
  @date('end') end;
  @text('location') location;
  @text('type') type;
  @text('rrule') rrule;
  @text('cancellation_reason') cancellationReason;
  @text('cancellation_more_info') cancellationMoreInfo;
  @text('note_template_id') noteTemplateId;
  @text('address') address;
  @json('custom_address', value => value) customAddress;

  @writer async cancel(
    cancellationReason: string,
    cancellationMoreInfo: string,
  ) {
    await this.update(entity => {
      entity.cancellationReason = cancellationReason;
      entity.cancellationMoreInfo = cancellationMoreInfo;
      entity.deletedBy = store.getState().authentication.userId;
      entity.deletedAt = new Date();
    });
  }
}
