import React from 'react';
import {Text, View} from 'react-native';
import {DataItem, FormSectionHeader} from 'src/design-system';
import {useStyle} from 'src/providers/style';
import {
  BirthDate,
  Email,
  FirstName,
  Gender,
  LastName,
  PhoneNumber,
  Tags,
} from 'src/hook-form-inputs';
import {Display as FirstNameDisplay} from 'src/hook-form-inputs/first-name';
import {Display as MiddleNameDisplay} from 'src/hook-form-inputs/middle-name';
import {Display as LastNameDisplay} from 'src/hook-form-inputs/last-name';
import {Display as GenderDisplay} from 'src/hook-form-inputs/gender';
import {Display as BirthDateDisplay} from 'src/hook-form-inputs/birth-date';
import {Display as PhoneNumberDisplay} from 'src/hook-form-inputs/phone-number';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import {Typography} from 'src/styles';
import {useTranslations} from 'src/providers/translation';
import addressFormat from 'src/common-utils/address-format';

export const fields = ['firstName', 'lastName', 'email'];

const PersonalInformationDisplay = ({values, tags}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const userTags = tags ? tags.map((tag: any) => tag.id) : values.tags;
  return (
    <View style={[styles.column]}>
      <FormSectionHeader title={'Personal Information'} />
      <FirstNameDisplay value={values.firstName} />
      <MiddleNameDisplay value={values.middleName} />
      <LastNameDisplay value={values.lastName} />
      <GenderDisplay value={values.gender} />
      <BirthDateDisplay value={values.birthDate} />
      <PhoneNumberDisplay value={values.mobilePhone} />
      <DataItem>
        <Text style={[Typography.H6]}>{translations('address')}</Text>
        <Text style={[Typography.P3]}>{addressFormat(values.address)}</Text>
      </DataItem>
      <Tags.Display value={userTags} type={'profile'} />
    </View>
  );
};

const PersonalInformationForm = () => {
  const styles = useStyle();
  return (
    <>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <FormSectionHeader title="Personal Information" />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <FirstName.Input />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <LastName.Input />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <Email.Input />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <Gender.Input />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <BirthDate.Input />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <PhoneNumber.Input />
        </View>
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <AddressAutocompleteInput label={'Address'} name={'address'} />
        </View>
      </View>
      <View style={[styles.flex, styles.paddingHorizontal]}>
        <FormSectionHeader title={'Tags'} />
      </View>
      <View style={[styles.container, styles.width]}>
        <View
          style={[styles.flex, styles.paddingHorizontal, styles.paddingBottom]}>
          <Tags.Input type={'profile'} />
        </View>
      </View>
    </>
  );
};

export const Display = PersonalInformationDisplay;

export default PersonalInformationForm;
