import {json, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

const sanitizePrompts = prompts => {
  return prompts === null
    ? null
    : Array.isArray(prompts)
    ? prompts.map(String)
    : [];
};

export class Event extends Base {
  static table = 'events';
  static associations = {
    sets: {type: 'belongs_to', key: 'set_id'},
    targets: {type: 'belongs_to', key: 'target_id'},
  };

  @relation('sets', 'set_id') set;
  @relation('targets', 'target_id') target;

  @text('value') value;
  @text('target_id') targetId;
  @json('prompts', sanitizePrompts) prompts;
  @text('intensity') intensity;
}
