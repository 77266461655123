import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {IconButton} from 'react-native-paper';
import Collapsible from 'react-native-collapsible';
import moment from 'moment';
import {cptCodes} from 'src/common-utils/cptCodes';
import {useStyle} from 'src/providers/style';
import {appointmentTypes} from 'src/form-inputs/appointment-type-input';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Appointment, Payer} from 'src/models';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import {startOfDay, endOfDay} from 'date-fns';
import {rrulestr} from 'rrule';
import _ from 'lodash';

const authorizationHeaders = [
  'Billing Code',
  'Authorized Units',
  'Used Units',
  // 'Scheduled Units',
  'Remaining Units',
];

interface Props {
  authorizationItem: any;
  canEdit: boolean;
  editSelected: (value: any) => void;
  canDelete: boolean;
  deleteSelected: (value: any) => void;
  completedSessions: any;
  scheduledSessions: any;
  appointments: any;
}

const PatientAuthorizationItem = ({
  authorizationItem,
  canEdit,
  editSelected,
  canDelete,
  deleteSelected,
  appointments,
}: Props) => {
  const styles = useStyle();
  const database = useDatabase();
  const [collapsed, setCollapsed] = useState(true);
  const [totalUnits, setTotalUnits] = useState<undefined | number>(undefined);
  const [completedUnits, setCompletedUnits] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scheduledUnits, setScheduledUnits] = useState<any>({});
  const [payerName, setPayerName] = useState<string>('');

  useEffect(() => {
    const fetchPayer = async () => {
      const payer = await database
        .get(Payer.table)
        .find(authorizationItem.payerId);
      if (payer) {
        setPayerName(payer.name);
      }
    };
    if (authorizationItem.payerId) {
      fetchPayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationItem.payerId]);

  const calculateUnits = async () => {
    const completedUnitsObj = {};
    const scheduledUnitsObj = {};
    let numberOfCompletedRecurringAppointments = 0;

    for (const appointment of appointments) {
      const sessions = await appointment.session;
      for (const session of sessions) {
        if (
          moment(session.date).isBetween(
            startOfDay(authorizationItem.startDate),
            endOfDay(authorizationItem.endDate),
          )
        ) {
          if (!appointment.rrule) {
            // not deleted
            if (!appointment.cancellationReason) {
              // completed
              if (session?.submissionTimestamp) {
                if (session?.cpt?.length) {
                  const startTimestamp =
                    session?.editedStartTimestamp || session?.startTimestamp;
                  const endTimestamp =
                    session?.editedEndTimestamp || session?.endTimestamp;

                  const durationInMins = moment(endTimestamp).diff(
                    startTimestamp,
                    'minutes',
                  );

                  const units =
                    session?.cpt.length &&
                    session?.cpt.length === 1 &&
                    session?.cpt[0] === 'H2012'
                      ? Math.floor(durationInMins / 60)
                      : Math.round(durationInMins / 15);

                  session?.cpt?.forEach((sessionCode: string) => {
                    if (completedUnitsObj[sessionCode]) {
                      completedUnitsObj[sessionCode] += units;
                    } else {
                      completedUnitsObj[sessionCode] = units;
                    }
                  });
                }
                // not started
              } else if (!session?.startTimestamp) {
                const appointmentParticipants =
                  await appointment.staffParticipants;

                for (const appointmentParticipant of appointmentParticipants) {
                  if (appointmentParticipant.cpt.length) {
                    const scheduledSessionStartTimestamp = appointment?.start;
                    const scheduledSessionEndTimestamp = appointment?.end;

                    const scheduledSessionDurationInMins = moment(
                      scheduledSessionEndTimestamp,
                    ).diff(scheduledSessionStartTimestamp, 'minutes');

                    const scheduledSessionUnits =
                      appointmentParticipant?.cpt.length &&
                      appointmentParticipant?.cpt.length === 1 &&
                      appointmentParticipant?.cpt[0] === 'H2012'
                        ? Math.floor(scheduledSessionDurationInMins / 60)
                        : Math.round(scheduledSessionDurationInMins / 15);

                    appointmentParticipant.cpt.forEach(
                      (participantCptCode: string) => {
                        if (scheduledUnitsObj[participantCptCode]) {
                          scheduledUnitsObj[participantCptCode] +=
                            scheduledSessionUnits;
                        } else {
                          scheduledUnitsObj[participantCptCode] =
                            scheduledSessionUnits;
                        }
                      },
                    );
                  }
                }
              }
            }
            // recurring
          } else {
            // completed
            if (session?.submissionTimestamp) {
              numberOfCompletedRecurringAppointments++;
              if (session?.cpt?.length) {
                const startTimestamp =
                  session?.editedStartTimestamp || session?.startTimestamp;
                const endTimestamp =
                  session?.editedEndTimestamp || session?.endTimestamp;

                const durationInMins = moment(endTimestamp).diff(
                  startTimestamp,
                  'minutes',
                );

                const units =
                  session?.cpt.length &&
                  session?.cpt.length === 1 &&
                  session?.cpt[0] === 'H2012'
                    ? Math.floor(durationInMins / 60)
                    : Math.round(durationInMins / 15);

                session?.cpt?.forEach((sessionCode: string) => {
                  if (completedUnitsObj[sessionCode]) {
                    completedUnitsObj[sessionCode] += units;
                  } else {
                    completedUnitsObj[sessionCode] = units;
                  }
                });
              }
            }
          }
        }
      }
    }

    for (const appointment of appointments) {
      const sessions = await appointment.session;
      for (const session of sessions) {
        if (
          moment(session.date).isBetween(
            startOfDay(authorizationItem.startDate),
            endOfDay(authorizationItem.endDate),
          )
        ) {
          // recurring appointments that have not started
          if (appointment.rrule && !session?.startTimestamp) {
            const rruleSet = rrulestr(appointment.rrule, {
              forceset: true,
            });

            const numberOfOccurrences =
              rruleSet.between(
                startOfDay(authorizationItem.startDate),
                endOfDay(authorizationItem.endDate),
              ).length - numberOfCompletedRecurringAppointments;

            const appointmentParticipants = await appointment.staffParticipants;

            for (const appointmentParticipant of appointmentParticipants) {
              if (appointmentParticipant.cpt.length) {
                const scheduledSessionStartTimestamp = appointment?.start;
                const scheduledSessionEndTimestamp = appointment?.end;

                const scheduledSessionDurationInMins = moment(
                  scheduledSessionEndTimestamp,
                ).diff(scheduledSessionStartTimestamp, 'minutes');

                const scheduledSessionUnits =
                  appointmentParticipant?.cpt.length &&
                  appointmentParticipant?.cpt.length === 1 &&
                  appointmentParticipant?.cpt[0] === 'H2012'
                    ? Math.floor(scheduledSessionDurationInMins / 60)
                    : Math.round(scheduledSessionDurationInMins / 15);

                _.times(numberOfOccurrences, () => {
                  appointmentParticipant.cpt.forEach(
                    (participantCptCode: string) => {
                      if (scheduledUnitsObj[participantCptCode]) {
                        scheduledUnitsObj[participantCptCode] +=
                          scheduledSessionUnits;
                      } else {
                        scheduledUnitsObj[participantCptCode] =
                          scheduledSessionUnits;
                      }
                    },
                  );
                });
              }
            }
          }
        }
      }
    }

    setCompletedUnits(completedUnitsObj);
    setScheduledUnits(scheduledUnitsObj);
  };

  useEffect(() => {
    let aggregate = 0;
    for (const cptCode of cptCodes) {
      aggregate += parseInt(authorizationItem[cptCode], 10);
    }
    setTotalUnits(aggregate);
    calculateUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationItem]);

  const getDate = (date: Date) => moment(date).format('L');

  return (
    <View style={[styles.card]}>
      <TouchableOpacity
        style={[
          styles.column,
          styles.justifyCenter,
          styles.alignItemsCenter,
          styles.paddingHorizontal,
        ]}
        onPress={() => setCollapsed(!collapsed)}>
        <View
          style={[
            styles.row,
            styles.justifySpaceBetween,
            styles.paddingLVertical,
          ]}>
          <View
            style={[
              styles.paddingLVertical,
              styles.row,
              styles.justifyContentFlexStart,
            ]}>
            <Text style={Typography.P2_MEDIUM}>{`${getDate(
              authorizationItem?.startDate,
            )} - ${getDate(authorizationItem?.endDate)}`}</Text>
            <View style={[styles.paddingLeft]}>
              <Text style={[Typography.P3]}>
                Auth #:
                {` ${
                  authorizationItem.authorizationNumber ||
                  'No Authorization Number'
                }`}
                {authorizationItem.payerId ? ` | ${payerName}` : ''}
              </Text>
            </View>
          </View>
          <View style={[styles.row, styles.justifySpaceBetween]}>
            <View style={[styles.hideMobile]}>
              <View style={[styles.paddingL]}>
                <Text style={Typography.P3}>
                  Total Authorized Units: {totalUnits}
                </Text>
              </View>
              <View style={[styles.paddingL]}>
                <Text style={[Typography.P3, styles.textColorSecondary]}>
                  {(totalUnits / 4).toFixed(2)} hrs
                </Text>
              </View>
            </View>
            {canEdit ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="pencil"
                onPress={editSelected}
              />
            ) : (
              <></>
            )}
            {canDelete ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="trash-can"
                onPress={deleteSelected}
              />
            ) : (
              <></>
            )}
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
            />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible
        collapsed={collapsed}
        renderChildrenCollapsed={false}
        style={[styles.paddingBottom, styles.paddingHorizontal]}>
        <Text style={[Typography.P3_BOLD, styles.paddingRight]}>
          Authorization Number: {authorizationItem.authorizationNumber}
        </Text>
        <View style={[styles.marginSM]}>
          {totalUnits ? (
            <View style={[styles.showMobile]}>
              <Text style={Typography.P3}>
                Total Authorized Units: {totalUnits}
              </Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                {(totalUnits / 4).toFixed(2)} hrs
              </Text>
            </View>
          ) : null}

          <View style={[styles.flex]}>
            <View style={[styles.row, styles.borderBottom]}>
              {authorizationHeaders.map((header: any, index: number) => (
                <View
                  key={`header-${header}-${index}`}
                  style={[
                    styles.paddingL,
                    styles.borderRight,
                    styles.justifyCenter,
                    header === 'Billing Code' ? styles.flex13 : styles.flex,
                    header === 'Billing Code'
                      ? styles.alignStart
                      : styles.alignCenter,
                  ]}>
                  <Text style={[Typography.P3]}>{header}</Text>
                </View>
              ))}
            </View>
            {cptCodes.map((code, index) => {
              const cptNumString = code.replace('cpt', '');
              const remainingUnits =
                authorizationItem[code] - (completedUnits[cptNumString] || 0) ||
                //  -(scheduledUnits[cptNumString] || 0)
                0;

              return (
                <View
                  key={`${authorizationItem.id}-${index}-auth-item`}
                  style={[
                    styles.row,
                    styles.borderBottom,
                    styles.alignCenter,
                    styles.justifyCenter,
                  ]}>
                  <View
                    style={[
                      styles.paddingL,
                      styles.borderRight,
                      styles.flex13,
                      styles.alignCenter,
                      styles.justifyCenter,
                    ]}>
                    <View style={[styles.width, styles.alignStart]}>
                      <Text style={[Typography.P3, styles.textColorPrimary]}>
                        {
                          appointmentTypes.find(
                            appointmentType =>
                              appointmentType.label.indexOf(
                                code.replace('cpt', ''),
                              ) !== -1,
                          ).label
                        }
                      </Text>
                    </View>
                  </View>

                  <View
                    style={[
                      styles.paddingL,
                      styles.borderRight,
                      styles.flex,
                      styles.alignCenter,
                      styles.justifyCenter,
                    ]}>
                    <View style={[styles.width, styles.alignCenter]}>
                      <Text style={[Typography.P3, styles.textColorPrimary]}>
                        {`${authorizationItem[code]}`}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={[
                      styles.paddingL,
                      styles.borderRight,
                      styles.flex,
                      styles.alignCenter,
                      styles.justifyCenter,
                    ]}>
                    <View style={[styles.width, styles.alignCenter]}>
                      <Text style={[Typography.P3, styles.textColorPrimary]}>
                        {completedUnits[cptNumString] || '0'}
                      </Text>
                    </View>
                  </View>

                  {/* scheduled units */}
                  {/* <View
                    style={[
                      styles.paddingL,
                      styles.borderRight,
                      styles.flex,
                      styles.alignCenter,
                      styles.justifyCenter,
                      styles.height,
                    ]}>
                    <View style={[styles.width, styles.alignCenter]}>
                      <Text style={[Typography.P3, styles.textColorPrimary]}>
                        {scheduledUnits[cptNumString] || '0'}
                      </Text>
                    </View>
                  </View> */}

                  <View
                    style={[
                      styles.paddingL,
                      styles.borderRight,
                      styles.flex,
                      styles.alignCenter,
                      styles.justifyCenter,
                    ]}>
                    <View style={[styles.width, styles.alignCenter]}>
                      <Text
                        style={[
                          Typography.P3,
                          remainingUnits < 0
                            ? styles.textColorError
                            : styles.textColorPrimary,
                        ]}>
                        {remainingUnits}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </Collapsible>
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['patient'], ({database, patient}: any) => {
    return {
      appointments: database
        .get(Appointment.table)
        .query(
          Q.on('participants', 'patient_id', patient.id),
          Q.where('deleted_at', null),
        ),
    };
  }),
)(PatientAuthorizationItem);
