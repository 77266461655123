import React, {useState} from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {useTranslations} from 'src/providers/translation';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {DataItem, FormSectionHeader} from 'src/design-system';
import _ from 'lodash';
import moment from 'moment/moment';
import Collapsible from 'react-native-collapsible';
import {IconButton} from 'react-native-paper';
import {Display as SSNDisplay} from 'src/hook-form-inputs/social-security-number';
import addressFormat from 'src/common-utils/address-format';
import {getInsuranceTitle} from '../insurance-item';
import {Patient} from 'src/models';
// import {Separator} from 'src/common-components/atoms';

const ClaimFormReview = ({claim, patient}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const [collapseBillingEntity, setCollapseBillingEntity] =
    useState<boolean>(true);
  const [collapsePatientEntity, setCollapsePatientEntity] =
    useState<boolean>(true);
  const [collapseInsurance, setCollapseInsurance] = useState<boolean>(true);
  const [collapseDiagnoses, setCollapseDiagnoses] = useState<boolean>(true);
  const [collapseServices, setCollapseServices] = useState<boolean>(true);
  const [collapseAdditionalClaimInfo, setCollapseAdditionalClaimInfo] =
    useState<boolean>(true);
  const values = claim.payload;

  return (
    <ScrollView contentContainerStyle={[styles.affixPadding]}>
      <Text
        style={[
          Typography.H5,
          styles.textColorPrimary,
          styles.paddingTop,
          styles.paddingHorizontal,
        ]}>
        {translations('claim_review')}
      </Text>

      <View style={[styles.blackCard, styles.marginVertical, styles.padding]}>
        <View style={[styles.width, styles.row]}>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            <DataItem>
              <View>
                <Text style={[styles.textColorWhite, Typography.H6]}>
                  {translations('client_name')}
                </Text>
                <Text style={[styles.textColorWhite, Typography.P3]}>
                  {patient.firstName} {patient.lastName}
                </Text>
              </View>
            </DataItem>
          </View>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            <DataItem>
              <View>
                <Text style={[styles.textColorWhite, Typography.H6]}>
                  {translations('pcn')}
                </Text>
                <Text style={[styles.textColorWhite, Typography.P3]}>
                  {claim?.kafka}
                </Text>
              </View>
            </DataItem>
          </View>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            <DataItem>
              <View>
                <Text style={[styles.textColorWhite, Typography.H6]}>
                  {translations('claim_status')}
                </Text>
                <Text style={[styles.textColorWhite, Typography.P3]}>
                  {_.capitalize(claim?.status)}
                </Text>
              </View>
            </DataItem>
          </View>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            <DataItem>
              <View>
                <Text style={[styles.textColorWhite, Typography.H6]}>
                  {translations('payer')}
                </Text>
                <Text style={[styles.textColorWhite, Typography.P3]}>
                  {values?.Insurances[0]?.PayerName} -{' '}
                  {values?.Insurances[0]?.PayerID.ID}
                </Text>
              </View>
            </DataItem>
          </View>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            <DataItem>
              <View>
                <Text style={[styles.textColorWhite, Typography.H6]}>
                  {translations('tin_abbreviated')}
                </Text>
                <Text style={[styles.textColorWhite, Typography.P3]}>
                  {values?.BillingProvID?.EIN}
                </Text>
              </View>
            </DataItem>
          </View>
          <View style={[styles.flex, styles.column, styles.marginRightAuto]}>
            <DataItem>
              <View>
                <Text style={[styles.textColorWhite, Typography.H6]}>
                  {translations('npi')}
                </Text>
                <Text style={[styles.textColorWhite, Typography.P3]}>
                  {values?.BillingProvID?.NPI}
                </Text>
              </View>
            </DataItem>
          </View>
        </View>
      </View>

      {/* COMPONENTIZE THIS VIEW */}
      {/* <View
        style={[
          styles.flex,
          styles.padding,
          styles.marginHorizontal,
          styles.backgroundColorPrimary50,
          styles.border,
          styles.borderPrimary,
          styles.borderRadiusSM,
        ]}>
        <View style={[styles.container, styles.alignCenter]}>
          <FormSectionHeader title={`Service ${'index + 1'}`} />
          <Separator width={20} />
          <Text style={[Typography.P3]}>
            {'service?.FromDate'} - {'service?.ThroughDate'}
          </Text>
        </View>

        <View style={[styles.container]}>
          <View
            style={[
              styles.flex,
              styles.marginRightAuto,
              styles.container,
              styles.paddingLeft,
            ]}>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('billing_code')}
                </Text>
                <Text style={[Typography.P3]}>{'service?.HCPC'}</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Modifier 1</Text>
                <Text style={[Typography.P3]}>{'service?.Modifier1'}</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Modifier 2</Text>
                <Text style={[Typography.P3]}>{'service?.Modifier2'}</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Modifier 3</Text>
                <Text style={[Typography.P3]}>{'service?.Modifier3'}</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>Modifier 4</Text>
                <Text style={[Typography.P3]}>{'service?.Modifier4'}</Text>
              </DataItem>
            </View>
          </View>
        </View>

        <View style={[styles.container]}>
          <View
            style={[
              styles.flex,
              styles.marginRightAuto,
              styles.container,
              styles.paddingLeft,
            ]}>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>{translations('units')}</Text>
                <Text style={[Typography.P3]}>{'service?.Units'}</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('charged_amount')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('adjusted_amount')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('allowed_amount')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('paid_to_provider')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
          </View>
        </View>
        <View
          style={[
            styles.width,
            styles.borderBottom,
            styles.borderPrimary,
            styles.marginVertical,
          ]}
        />
        <View style={[styles.container]}>
          <View
            style={[
              styles.flex,
              styles.marginRightAuto,
              styles.container,
              styles.paddingLeft,
            ]}>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('payment_date')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('charged_amount')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('adjusted_amount')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('allowed_amount')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('patient_responsibility')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
            <View style={[styles.flex]}>
              <DataItem>
                <Text style={[Typography.H6]}>
                  {translations('paid_to_provider')}
                </Text>
                <Text style={[Typography.P3]}>-</Text>
              </DataItem>
            </View>
          </View>
          <View
            style={[
              styles.alignSelfEnd,
              styles.border,
              styles.borderPrimary,
              styles.borderRadiusSM,
              styles.paddingM,
            ]}>
            <Text style={[Typography.P3]}>Total Due ????</Text>
          </View>
        </View>
      </View> */}

      {!values ? (
        <View style={[styles.alignCenter, styles.padding]}>
          <Text style={[Typography.H6]}>Your claim is being submitted</Text>
        </View>
      ) : (
        <>
          <View style={[styles.card, styles.marginVertical, styles.padding]}>
            <TouchableOpacity
              onPress={() => setCollapseBillingEntity(!collapseBillingEntity)}>
              <View style={[styles.row, styles.justifySpaceBetween]}>
                <FormSectionHeader title={translations('billing_entity')} />
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon={!collapseBillingEntity ? 'chevron-up' : 'chevron-down'}
                />
              </View>
            </TouchableOpacity>
            <Collapsible collapsed={collapseBillingEntity}>
              <>
                <View style={[styles.width, styles.row]}>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          DBA (Doing Business As)
                        </Text>
                        <Text style={[Typography.P3]}>
                          {values?.BillingName}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          National Provider Identifier
                        </Text>
                        <Text style={[Typography.P3]}>
                          {values?.BillingProvID?.NPI}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          Tax Identification Number
                        </Text>
                        <Text style={[Typography.P3]}>
                          {values?.BillingProvID?.EIN}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                </View>
                <View style={[styles.width, styles.row]}>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>Taxonomy</Text>
                        <Text style={[Typography.P3]}>
                          {values?.BillingProvID?.TAXONOMY}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          {translations('billing_address')}
                        </Text>
                        <Text style={[Typography.P3]}>
                          {addressFormat({
                            streetLine: values?.BillingAddr1,
                            secondLine: values?.BillingAddr2,
                            city: values?.BillingCity,
                            state: values?.BillingState,
                            postalCode: values?.BillingZip,
                            country: values?.BillingCountry,
                          })}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[styles.flex, styles.column, styles.marginRightAuto]}
                  />
                </View>
              </>
            </Collapsible>
          </View>
          <View style={[styles.card, styles.marginVertical, styles.padding]}>
            <TouchableOpacity
              onPress={() => setCollapsePatientEntity(!collapsePatientEntity)}>
              <View style={[styles.row, styles.justifySpaceBetween]}>
                <FormSectionHeader title={translations('patient_entity')} />
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon={!collapsePatientEntity ? 'chevron-up' : 'chevron-down'}
                />
              </View>
            </TouchableOpacity>
            <Collapsible collapsed={collapsePatientEntity}>
              <>
                <View style={[styles.width, styles.row]}>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          {translations('first_name')}
                        </Text>
                        <Text style={[Typography.P3]}>
                          {values?.PatientFirst}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          {translations('middle_name')}
                        </Text>
                        <Text style={[Typography.P3]}>
                          {values?.PatientMiddle}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          {translations('last_name')}
                        </Text>
                        <Text style={[Typography.P3]}>
                          {values?.PatientLast}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                </View>
                <View style={[styles.width, styles.row]}>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          {translations('gender')}
                        </Text>
                        <Text style={[Typography.P3]}>
                          {_.capitalize(values?.PatientGender)}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          {translations('birth_date')}
                        </Text>
                        <Text style={[Typography.P3]}>
                          {moment(values?.PatientDOB).format('MM/DD/YYYY')}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <SSNDisplay value={values?.PatientID?.SSN} />
                  </View>
                </View>
                <View style={[styles.width, styles.row]}>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          {translations('address')}
                        </Text>
                        <Text style={[Typography.P3]}>
                          {addressFormat({
                            streetLine: values?.PatientAddr1,
                            secondLine: values?.PatientAddr2,
                            city: values?.PatientCity,
                            state: values?.PatientState,
                            postalCode: values?.PatientZip,
                            country: values?.PatientCountry,
                          })}
                        </Text>
                      </View>
                    </DataItem>
                  </View>
                </View>
              </>
            </Collapsible>
          </View>
          <View style={[styles.card, styles.marginVertical, styles.padding]}>
            <TouchableOpacity
              onPress={() => setCollapseInsurance(!collapseInsurance)}>
              <View style={[styles.row, styles.justifySpaceBetween]}>
                <FormSectionHeader title={translations('insurance')} />
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon={!collapseInsurance ? 'chevron-up' : 'chevron-down'}
                />
              </View>
            </TouchableOpacity>
            <Collapsible collapsed={collapseInsurance}>
              {values?.Insurances?.map((insurance, index) => (
                <View key={index}>
                  <FormSectionHeader title={getInsuranceTitle(index)} />
                  <DataItem>
                    <Text style={[Typography.H6]}>Assign Benefits</Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.AssignBenefits}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Group Number</Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.GroupNumber}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>
                      Patient Relationship Code
                    </Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.PatientRelateCode}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Payer Address</Text>
                    <Text style={[Typography.P3]}>
                      {addressFormat({
                        streetLine: insurance?.PayerAddr1,
                        secondLine: insurance?.PayerAddr2,
                        city: insurance?.PayerCity,
                        state: insurance?.PayerState,
                        postalCode: insurance?.PayerZip,
                        country: insurance?.PayerCountry,
                      })}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Payer ID</Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.PayerID?.ID}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Payer Name</Text>
                    <Text style={[Typography.P3]}>{insurance?.PayerName}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>
                      Prior Authorization Number
                    </Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.PriorAuthNo}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Release Info Code</Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.ReleaseInfoCode}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Subscriber Name</Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.SubscriberFirst} {insurance?.SubscriberLast}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Subscriber ID</Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.SubscriberID?.MemberID}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Subscriber Address</Text>
                    <Text style={[Typography.P3]}>
                      {addressFormat({
                        streetLine: insurance?.SubscriberAddr1,
                        secondLine: insurance?.SubscriberAddr2,
                        city: insurance?.SubscriberCity,
                        state: insurance?.SubscriberState,
                        postalCode: insurance?.SubscriberZip,
                        country: insurance?.SubscriberCountry,
                      })}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>
                      Subscriber Date of Birth
                    </Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.SubscriberDOB}
                    </Text>
                  </DataItem>

                  <DataItem>
                    <Text style={[Typography.H6]}>Subscriber Phone</Text>
                    <Text style={[Typography.P3]}>
                      {insurance?.SubscriberPhone}
                    </Text>
                  </DataItem>
                </View>
              ))}
            </Collapsible>
          </View>
          <View style={[styles.card, styles.marginVertical, styles.padding]}>
            <TouchableOpacity
              onPress={() => setCollapseDiagnoses(!collapseDiagnoses)}>
              <View style={[styles.row, styles.justifySpaceBetween]}>
                <FormSectionHeader title={translations('diagnoses')} />
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon={!collapseDiagnoses ? 'chevron-up' : 'chevron-down'}
                />
              </View>
            </TouchableOpacity>
            <Collapsible collapsed={collapseDiagnoses}>
              {Object.values(values?.DiagnosisCodes10).map(
                (diagnosis, index) => (
                  <DataItem key={index}>
                    <Text style={[Typography.H6]}>
                      Diagnosis Code {index + 1}
                    </Text>
                    <Text style={[Typography.P3]}>{diagnosis}</Text>
                  </DataItem>
                ),
              )}
            </Collapsible>
          </View>
          <View style={[styles.card, styles.marginVertical, styles.padding]}>
            <TouchableOpacity
              onPress={() => setCollapseServices(!collapseServices)}>
              <View style={[styles.row, styles.justifySpaceBetween]}>
                <FormSectionHeader title={translations('services')} />
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon={!collapseServices ? 'chevron-up' : 'chevron-down'}
                />
              </View>
            </TouchableOpacity>

            <Collapsible collapsed={collapseServices}>
              {values?.Services?.map((service, index) => (
                <View key={index} style={[styles.paddingBottom]}>
                  <FormSectionHeader title={`Service ${index + 1}`} />
                  <DataItem>
                    <Text style={[Typography.H6]}>HCPC</Text>
                    <Text style={[Typography.P3]}>{service?.HCPC}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Line No.</Text>
                    <Text style={[Typography.P3]}>{service?.LineNo}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Description</Text>
                    <Text style={[Typography.P3]}>{service?.Description}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Diagnosis Pointer</Text>
                    <Text style={[Typography.P3]}>
                      {service?.DiagnosisPointer}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>From Date</Text>
                    <Text style={[Typography.P3]}>{service?.FromDate}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Through Date</Text>
                    <Text style={[Typography.P3]}>{service?.ThroughDate}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Modifier 1</Text>
                    <Text style={[Typography.P3]}>{service?.Modifier1}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Modifier 2</Text>
                    <Text style={[Typography.P3]}>{service?.Modifier2}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Modifier 3</Text>
                    <Text style={[Typography.P3]}>{service?.Modifier3}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Modifier 4</Text>
                    <Text style={[Typography.P3]}>{service?.Modifier4}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Units</Text>
                    <Text style={[Typography.P3]}>{service?.Units}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Charge</Text>
                    <Text style={[Typography.P3]}>{service?.Charge}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Place of Service</Text>
                    <Text style={[Typography.P3]}>{service?.PlaceService}</Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Rendering Provider</Text>
                    <Text style={[Typography.P3]}>
                      {service?.RenderingFirst} {service?.RenderingLast}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>NPI</Text>
                    <Text style={[Typography.P3]}>
                      {service?.RenderingProvID?.NPI}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>EIN</Text>
                    <Text style={[Typography.P3]}>
                      {service?.RenderingProvID?.EIN}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Blue Cross</Text>
                    <Text style={[Typography.P3]}>
                      {service?.RenderingProvID?.Bluecross}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Blue Shield</Text>
                    <Text style={[Typography.P3]}>
                      {service?.RenderingProvID?.Blueshield}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Medicaid</Text>
                    <Text style={[Typography.P3]}>
                      {service?.RenderingProvID?.Medicaid}
                    </Text>
                  </DataItem>
                  <DataItem>
                    <Text style={[Typography.H6]}>Medicare</Text>
                    <Text style={[Typography.P3]}>
                      {service?.RenderingProvID?.Medicare}
                    </Text>
                  </DataItem>
                </View>
              ))}
            </Collapsible>
          </View>
          <View style={[styles.card, styles.marginVertical, styles.padding]}>
            <TouchableOpacity
              onPress={() =>
                setCollapseAdditionalClaimInfo(!collapseAdditionalClaimInfo)
              }>
              <View style={[styles.row, styles.justifySpaceBetween]}>
                <FormSectionHeader
                  title={translations('additional_claim_information')}
                />
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon={
                    !collapseAdditionalClaimInfo ? 'chevron-up' : 'chevron-down'
                  }
                />
              </View>
            </TouchableOpacity>
            <Collapsible collapsed={collapseAdditionalClaimInfo}>
              <>
                {/*<View style={[styles.width, styles.row]}>*/}
                {/*  <View*/}
                {/*    style={[styles.flex, styles.column, styles.marginRightAuto]}>*/}
                {/*    <DataItem>*/}
                {/*      <View>*/}
                {/*        <Text style={[Typography.H6]}>*/}
                {/*          Referring Physician First Name*/}
                {/*        </Text>*/}
                {/*        <Text style={[Typography.P3]}>{referring.firstName}</Text>*/}
                {/*      </View>*/}
                {/*    </DataItem>*/}
                {/*  </View>*/}
                {/*  <View*/}
                {/*    style={[styles.flex, styles.column, styles.marginRightAuto]}>*/}
                {/*    <DataItem>*/}
                {/*      <View>*/}
                {/*        <Text style={[Typography.H6]}>*/}
                {/*          Referring Physician Middle Name*/}
                {/*        </Text>*/}
                {/*        <Text style={[Typography.P3]}> {referring.middleName}</Text>*/}
                {/*      </View>*/}
                {/*    </DataItem>*/}
                {/*  </View>*/}
                {/*  <View*/}
                {/*    style={[styles.flex, styles.column, styles.marginRightAuto]}>*/}
                {/*    <DataItem>*/}
                {/*      <View>*/}
                {/*        <Text style={[Typography.H6]}>*/}
                {/*          Referring Physician Last Name*/}
                {/*        </Text>*/}
                {/*        <Text style={[Typography.P3]}> {referring.lastName}</Text>*/}
                {/*      </View>*/}
                {/*    </DataItem>*/}
                {/*  </View>*/}
                {/*</View>*/}
                {/*<View style={[styles.width, styles.row]}>*/}
                {/*  <View*/}
                {/*    style={[styles.flex, styles.column, styles.marginRightAuto]}>*/}
                {/*    <DataItem>*/}
                {/*      <View>*/}
                {/*        <Text style={[Typography.H6]}>*/}
                {/*          Referring Physician Taxonomy*/}
                {/*        </Text>*/}
                {/*        <Text style={[Typography.P3]}>{referring.taxonomy}</Text>*/}
                {/*      </View>*/}
                {/*    </DataItem>*/}
                {/*  </View>*/}
                {/*  <View*/}
                {/*    style={[styles.flex, styles.column, styles.marginRightAuto]}>*/}
                {/*    <DataItem>*/}
                {/*      <View>*/}
                {/*        <Text style={[Typography.H6]}>Referring Physician NPI</Text>*/}
                {/*        <Text style={[Typography.P3]}> {referring.npi}</Text>*/}
                {/*      </View>*/}
                {/*    </DataItem>*/}
                {/*  </View>*/}
                {/*  <View*/}
                {/*    style={[styles.flex, styles.column, styles.marginRightAuto]}>*/}
                {/*    <DataItem>*/}
                {/*      <View>*/}
                {/*        <Text style={[Typography.H6]}>Referring Physician TIN</Text>*/}
                {/*        <Text style={[Typography.P3]}> {referring.tin}</Text>*/}
                {/*      </View>*/}
                {/*    </DataItem>*/}
                {/*  </View>*/}
                {/*</View>*/}
                <View style={[styles.width, styles.row]}>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          Reserved for NUCC Use (Box 8)
                        </Text>
                        <Text style={[Typography.P3]}>{values?.Box8}</Text>
                      </View>
                    </DataItem>
                  </View>

                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          Reserved for NUCC Use (Box 9b)
                        </Text>
                        <Text style={[Typography.P3]}>{values?.Box9b}</Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          Reserved for NUCC Use (Box 9c)
                        </Text>
                        <Text style={[Typography.P3]}>{values?.Box9c}</Text>
                      </View>
                    </DataItem>
                  </View>
                </View>
                <View style={[styles.width, styles.row]}>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          Claim Codes (Box 10d)
                        </Text>
                        <Text style={[Typography.P3]}>{values?.Box10d}</Text>
                      </View>
                    </DataItem>
                  </View>

                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          Additional Claim Information (Box 19)
                        </Text>
                        <Text style={[Typography.P3]}>{values?.Box19}</Text>
                      </View>
                    </DataItem>
                  </View>
                  <View
                    style={[
                      styles.flex,
                      styles.column,
                      styles.marginRightAuto,
                    ]}>
                    <DataItem>
                      <View>
                        <Text style={[Typography.H6]}>
                          Reserved for NUCC Use (Box 30)
                        </Text>
                        <Text style={[Typography.P3]}>{values?.Box30}</Text>
                      </View>
                    </DataItem>
                  </View>
                </View>
              </>
            </Collapsible>
          </View>
        </>
      )}
    </ScrollView>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['claim'], ({claim, database}: any) => {
    return {
      patient: database.get(Patient.table).findAndObserve(claim.patientId),
    };
  }),
)(ClaimFormReview);
