import React from 'react';
import {View, Text} from 'react-native';
import {DataTable} from 'react-native-paper';
import EStyleSheet from 'react-native-extended-stylesheet';
import {useDimensions} from '@react-native-community/hooks';
import IconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import _ from 'lodash';
import moment from 'moment';

import {Colors, Typography} from 'src/styles';
import SkillTableTooltip from 'src/modules/session/components/skill-table-tooltip';

const TrialByTrialTable = ({program, events, target, sets}: any) => {
  const dimensions = useDimensions();
  const eventsBySetId = _.groupBy(events, 'set.id');
  const width = dimensions.window.width;
  const styles = getStyles(dimensions.window.width);
  const trialCount = program?.numberOfTrials;
  if (width > 768 || trialCount < 5) {
    return (
      <DataTable style={styles.table}>
        <DataTable.Header style={[styles.head, styles.firstRow]}>
          <View
            style={[styles.setHead, styles.justifyCenter, styles.borderRight]}>
            <Text style={styles.titleTxt}>{target?.name}</Text>
          </View>
          {_.range(0, trialCount || 10).map((event: any, idx: number) => (
            <DataTable.Title
              style={[styles.justifyCenter, styles.borderRight]}
              textStyle={styles.titleTxt}
              key={`trialbytrial-target-score-key-${idx}`}>
              {idx + 1}
            </DataTable.Title>
          ))}
          <DataTable.Title
            style={styles.justifyCenter}
            textStyle={styles.titleTxt}>
            Score
          </DataTable.Title>
        </DataTable.Header>
        {sets?.map((set: any, idx: number) => {
          const avg = Math.ceil(
            (eventsBySetId[set?.id]?.filter(
              (event: any) => event.value === 'success',
            ).length /
              eventsBySetId[set?.id]?.length) *
              100,
          );
          const average = isNaN(avg) ? 0 : avg;
          return (
            <DataTable.Row
              key={`trialbytrial-set-key-${set?.id}`}
              style={sets?.length - 1 === idx ? styles.lastRow : {}}>
              <View
                style={[
                  styles.justifyCenter,
                  styles.setHead,
                  styles.borderRight,
                  styles.pv_5,
                ]}>
                <Text style={styles.cellBoldTxt}>{'Set ' + (idx + 1)}</Text>
                <Text style={styles.cellTxt}>
                  {moment(set?.startTimestamp).format('ll')}
                </Text>
                <Text style={styles.cellTxt}>
                  {moment(set?.startTimestamp).format('hh:mm A')} -{' '}
                  {set?.endTimestamp
                    ? moment(set?.endTimestamp).format('hh:mm A')
                    : 'Incomplete'}
                </Text>
              </View>
              {_.range(0, trialCount || 10).map((e: any, i: number) => {
                const event = eventsBySetId[set?.id]?.[i]
                  ? eventsBySetId[set?.id]?.[i]
                  : null;
                if (event?.value) {
                  return (
                    <View
                      style={[
                        styles.justifyCenter,
                        styles.borderRight,
                        styles.flex,
                      ]}
                      key={`trialbytrial-value-key-${set?.id}-${i}`}>
                      <SkillTableTooltip
                        type={'trial_by_trial'}
                        setIndex={idx + 1}
                        event={event}
                        target={target?.name}
                        task={i + 1}
                        set={set}
                      />
                      <View style={styles.cornerTab} />
                    </View>
                  );
                } else {
                  return (
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      key={`trialbytrial-value-key-${set?.id}-${i}`}>
                      <IconMaterial
                        name="minus"
                        size={12}
                        color={Colors.TEXT_SECONDARY}
                      />
                    </DataTable.Cell>
                  );
                }
              })}
              <DataTable.Cell
                style={styles.justifyCenter}
                textStyle={styles.cellBoldTxt}>
                {average + '%'}
              </DataTable.Cell>
            </DataTable.Row>
          );
        })}
      </DataTable>
    );
  } else {
    return (
      <>
        <DataTable style={styles.mobileTable}>
          <DataTable.Header style={[styles.head, styles.pr_0]}>
            <View
              style={[
                styles.justifyCenter,
                styles.setHead,
                styles.borderRight,
              ]}>
              <Text style={styles.titleTxt}>{target?.name}</Text>
            </View>
            {_.range(0, 5).map((event: any, idx: number) => (
              <DataTable.Title
                style={[
                  styles.justifyCenter,
                  idx !== 4 ? styles.borderRight : {},
                ]}
                textStyle={styles.titleTxt}
                key={`trialbytrial-target-score-key-${idx}`}>
                {idx + 1}
              </DataTable.Title>
            ))}
          </DataTable.Header>
          {sets?.map((set: any, idx: number) => {
            return (
              <DataTable.Row
                key={`trialbytrial-set-key-${set?.id}-${idx}`}
                style={[
                  styles.pr_0,
                  sets?.length - 1 === idx ? styles.lastRow : {},
                ]}>
                <View
                  style={[
                    styles.justifyCenter,
                    styles.setHead,
                    styles.borderRight,
                    styles.pv_5,
                  ]}>
                  <Text style={styles.cellBoldTxt}>{'Set ' + (idx + 1)}</Text>
                  <Text style={styles.cellTxt}>
                    {moment(set?.startTimestamp).format('ll')}
                  </Text>
                  <Text style={styles.cellTxt}>
                    {moment(set?.startTimestamp).format('hh:mm A')} -{' '}
                    {set?.endTimestamp
                      ? moment(set?.endTimestamp).format('hh:mm A')
                      : 'Incomplete'}
                  </Text>
                </View>
                {_.range(0, 5).map((e: any, i: number) => {
                  const event = eventsBySetId[set?.id]?.[i]
                    ? eventsBySetId[set?.id]?.[i]
                    : null;
                  if (event?.value) {
                    return (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.borderRight,
                          styles.flex,
                        ]}
                        key={`trialbytrial-value-key-${set?.id}-${i}`}>
                        <SkillTableTooltip
                          type={'trial_by_trial'}
                          setIndex={idx + 1}
                          event={event}
                          target={target?.name}
                          task={i + 1}
                          set={set}
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    );
                  } else {
                    return (
                      <DataTable.Cell
                        style={[styles.justifyCenter, styles.borderRight]}
                        key={`trialbytrial-value-key-${set?.id}-${i}`}>
                        <IconMaterial
                          name="minus"
                          size={12}
                          color={Colors.TEXT_SECONDARY}
                        />
                      </DataTable.Cell>
                    );
                  }
                })}
              </DataTable.Row>
            );
          })}
        </DataTable>
        <DataTable style={styles.tableBorder}>
          <DataTable.Header style={styles.secondHead}>
            {_.range(5, trialCount).map((event: any, idx: number) => (
              <DataTable.Title
                style={[styles.justifyCenter, styles.borderRight]}
                textStyle={styles.titleTxt}
                key={`trialbytrial-target-score-key-${idx}`}>
                {idx + 6}
              </DataTable.Title>
            ))}
            <DataTable.Title
              style={styles.justifyCenter}
              textStyle={styles.titleTxt}>
              Score
            </DataTable.Title>
          </DataTable.Header>
          {sets?.map((set: any, idx: number) => {
            const avg = Math.ceil(
              (eventsBySetId[set?.id]?.filter(
                (event: any) => event.value === 'success',
              ).length /
                eventsBySetId[set?.id]?.length) *
                100,
            );
            const average = isNaN(avg) ? 0 : avg;
            return (
              <DataTable.Row
                key={`trialbytrial-set-key-${set?.id}-${idx}`}
                style={sets?.length - 1 === idx ? styles.lastRow : {}}>
                {_.range(5, trialCount).map((e: any, i: number) => {
                  const newKey = i + 5;
                  const event = eventsBySetId[set?.id][newKey]
                    ? eventsBySetId[set?.id][newKey]
                    : null;
                  if (event?.value) {
                    return (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.borderRight,
                          styles.flex,
                        ]}
                        key={`trialbytrial-value-key-${set?.id}-${newKey}`}>
                        <SkillTableTooltip
                          type={'trial_by_trial'}
                          setIndex={idx + 1}
                          event={event}
                          target={target?.name}
                          task={newKey + 1}
                          set={set}
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    );
                  } else {
                    return (
                      <DataTable.Cell
                        style={[styles.justifyCenter, styles.borderRight]}
                        key={`trialbytrial-value-key-${set?.id}-${newKey}`}>
                        <IconMaterial
                          name="minus"
                          size={12}
                          color={Colors.TEXT_SECONDARY}
                        />
                      </DataTable.Cell>
                    );
                  }
                })}
                <DataTable.Cell
                  style={styles.justifyCenter}
                  textStyle={styles.cellBoldTxt}>
                  {average + '%'}
                </DataTable.Cell>
              </DataTable.Row>
            );
          })}
        </DataTable>
      </>
    );
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStyles = (width: number) =>
  EStyleSheet.create({
    table: {
      borderWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
      borderRadius: 5,
    },
    mobileTable: {
      borderWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
    },
    tableBorder: {
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
    },
    head: {
      minHeight: 80,
      backgroundColor: Colors.PRIMARY_50,
    },
    setHead: {
      width: 155,
      paddingVertical: 5,
    },
    secondHead: {
      height: 50,
      backgroundColor: Colors.PRIMARY_50,
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    alignCenter: {
      alignItems: 'center',
    },
    titleTxt: {
      ...Typography.LABEL_MEDIUM,
      color: Colors.PRIMARY_900,
      alignSelf: 'center',
      textAlign: 'center',
      flexWrap: 'wrap',
      paddingHorizontal: 2,
    },
    cellBoldTxt: {
      ...Typography.CAPTION_MEDIUM,
      color: Colors.PRIMARY_900,
    },
    cellTxt: {
      ...Typography.CAPTION,
      color: Colors.PRIMARY_900,
    },
    lastRow: {
      borderBottomStartRadius: 5,
      borderBottomEndRadius: 5,
    },
    hideMobile: {},
    cornerTab: {
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderRightWidth: 8,
      borderTopWidth: 8,
      borderRightColor: 'transparent',
      borderTopColor: '#B5B5B8',
      transform: [{rotate: '90deg'}],
      position: 'absolute',
      top: 0,
      right: 0,
    },
    borderRight: {
      borderRightWidth: 1,
      borderColor: Colors.PRIMARY_300_SESSION_PLAN,
    },
    pv_5: {
      paddingVertical: 5,
    },
    pt_10: {
      paddingTop: 10,
    },
    pr_0: {
      paddingRight: 0,
    },
    flex: {
      flex: 1,
    },
    firstRow: {
      borderTopStartRadius: 10,
      borderTopEndRadius: 10,
    },
    '@media (max-width: 768)': {
      titleTxt: {
        fontSize: Typography.FONT_SIZE_10,
      },
      cellBoldTxt: {
        marginTop: 5,
        fontSize: Typography.FONT_SIZE_10,
      },
      cellTxt: {
        fontSize: Typography.FONT_SIZE_10,
      },
      head: {
        height: 40,
      },
      secondHead: {
        height: 40,
      },
    },
    '@media (max-width: 1024)': {
      hideMobile: {
        display: 'none',
      },
      setHead: {
        width: 100,
      },
    },
  });

export default TrialByTrialTable;
