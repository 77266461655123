import React, {Fragment} from 'react';
import {StyleSheet, View} from 'react-native';
import {DataTable} from 'react-native-paper';
import {useDimensions} from '@react-native-community/hooks';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import {compose} from 'recompose';
import _ from 'lodash';
import FrequencyTableTooltip from '../../frequency-table-tooltip';
import {Colors, Typography} from 'src/styles';
import InformationTooltip from 'src/modules/programs/components/information-tooltip';

const FrequencyTable = ({
  setEvents,
  setInstance,
  type = undefined,
  events,
  program,
}: any) => {
  const dimensions = useDimensions();

  const width = dimensions.window.width;
  const countInRow = width > 1024 ? 10 : width > 768 ? 5 : width > 480 ? 3 : 2;
  const tableCount = Math.floor(events.length / countInRow);
  const restCount = countInRow - (events.length % countInRow);

  if (type === 'edit-modal') {
    return (
      <DataTable style={styles.table}>
        {_.times(tableCount + 1, i => {
          if (i < tableCount || (i === tableCount && restCount < countInRow)) {
            return (
              <>
                <DataTable.Header
                  style={[
                    styles.head,
                    styles.ph_0,
                    i === 0 && styles.firstRow,
                  ]}>
                  <DataTable.Title
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.titleTxt}>
                    Instance
                  </DataTable.Title>
                  {events
                    ?.slice(i * countInRow, (i + 1) * countInRow)
                    .map((event: any, index: number) => (
                      <DataTable.Title
                        style={[
                          styles.justifyCenter,
                          index !== countInRow - 1 &&
                          index !== events?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`frequency-instance-key-${index}`}
                        textStyle={styles.titleTxt}>
                        {i * countInRow + index + 1}
                      </DataTable.Title>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Title />)}
                </DataTable.Header>
                <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                  <DataTable.Cell
                    style={[styles.justifyCenter, styles.borderRight]}
                    textStyle={styles.cellBoldTxt}>
                    Timestamp
                  </DataTable.Cell>
                  {events
                    ?.slice(i * countInRow, (i + 1) * countInRow)
                    .map((event: any, index: number) => (
                      <View
                        style={[
                          styles.justifyCenter,
                          styles.flex,
                          index !== countInRow - 1 &&
                          index !== events?.length - 1
                            ? styles.borderRight
                            : {},
                        ]}
                        key={`frequency-timestamp-${index}`}>
                        <FrequencyTableTooltip
                          method={program?.method}
                          event={event}
                          instance={i * countInRow + index + 1}
                          intensity={
                            program?.intensity
                              ? _.capitalize(event?.intensity)
                              : ''
                          }
                        />
                        <View style={styles.cornerTab} />
                      </View>
                    ))}
                  {i > 0 &&
                    i === tableCount &&
                    _.times(restCount, () => <DataTable.Cell />)}
                </DataTable.Row>
                {program?.intensity && (
                  <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      Intensity
                    </DataTable.Cell>
                    {events
                      ?.slice(i * countInRow, (i + 1) * countInRow)
                      .map((event: any, index: number) => (
                        <DataTable.Cell
                          style={[
                            styles.justifyCenter,
                            index !== countInRow - 1 &&
                            index !== events?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          textStyle={styles.cellBoldTxt}
                          key={`frequency-timestamp-${index}`}>
                          <FrequencyTableTooltip
                            edit={true}
                            setEvents={setEvents}
                            setInstance={setInstance}
                            method={program?.method}
                            event={event}
                            instance={i * countInRow + index + 1}
                            intensity={
                              program?.intensity
                                ? _.capitalize(event?.intensity)
                                : ''
                            }
                          />
                          <View style={styles.cornerTab} />
                        </DataTable.Cell>
                      ))}
                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Cell />)}
                  </DataTable.Row>
                )}
              </>
            );
          }
        })}
      </DataTable>
    );
  } else {
    return (
      <>
        <View style={[styles.alignSelf]}>
          <InformationTooltip value="Frequency tables are not inclusive of 0 events" />
        </View>
        <DataTable style={styles.table}>
          {_.times(tableCount + 1, i => {
            if (
              i < tableCount ||
              (i === tableCount && restCount < countInRow)
            ) {
              return (
                <>
                  <DataTable.Header
                    style={[
                      styles.head,
                      styles.ph_0,
                      i === 0 && styles.firstRow,
                    ]}>
                    <DataTable.Title
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.titleTxt}>
                      Instance
                    </DataTable.Title>
                    {events
                      ?.slice(i * countInRow, (i + 1) * countInRow)
                      .map((event: any, index: number) => (
                        <DataTable.Title
                          style={[
                            styles.justifyCenter,
                            index !== countInRow - 1 &&
                            index !== events?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`frequency-instance-key-${index}`}
                          textStyle={styles.titleTxt}>
                          {i * countInRow + index + 1}
                        </DataTable.Title>
                      ))}
                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Title />)}
                  </DataTable.Header>
                  <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                    <DataTable.Cell
                      style={[styles.justifyCenter, styles.borderRight]}
                      textStyle={styles.cellBoldTxt}>
                      Timestamp
                    </DataTable.Cell>
                    {events
                      ?.slice(i * countInRow, (i + 1) * countInRow)
                      .map((event: any, index: number) => (
                        <View
                          style={[
                            styles.justifyCenter,
                            styles.flex,
                            index !== countInRow - 1 &&
                            index !== events?.length - 1
                              ? styles.borderRight
                              : {},
                          ]}
                          key={`frequency-timestamp-${index}`}>
                          <FrequencyTableTooltip
                            method={program?.method}
                            event={event}
                            instance={i * countInRow + index + 1}
                            intensity={
                              program?.intensity
                                ? _.capitalize(event?.intensity)
                                : ''
                            }
                          />
                          <View style={styles.cornerTab} />
                        </View>
                      ))}
                    {i > 0 &&
                      i === tableCount &&
                      _.times(restCount, () => <DataTable.Cell />)}
                  </DataTable.Row>
                  {program?.intensity && (
                    <DataTable.Row style={[styles.lastRow, styles.ph_0]}>
                      <DataTable.Cell
                        style={[styles.justifyCenter, styles.borderRight]}
                        textStyle={styles.cellBoldTxt}>
                        Intensity
                      </DataTable.Cell>
                      {events
                        ?.slice(i * countInRow, (i + 1) * countInRow)
                        .map((event: any, index: number) => (
                          <DataTable.Cell
                            style={[
                              styles.justifyCenter,
                              index !== countInRow - 1 &&
                              index !== events?.length - 1
                                ? styles.borderRight
                                : {},
                            ]}
                            textStyle={styles.cellBoldTxt}
                            key={`frequency-timestamp-${index}`}>
                            {_.capitalize(event?.intensity)}
                          </DataTable.Cell>
                        ))}
                      {i > 0 &&
                        i === tableCount &&
                        _.times(restCount, () => <DataTable.Cell />)}
                    </DataTable.Row>
                  )}
                </>
              );
            }
          })}
        </DataTable>
      </>
    );
  }
};

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
  },
  table: {
    borderWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
    borderRadius: 10,
  },
  head: {
    height: 50,
    backgroundColor: Colors.PRIMARY_50,
  },
  firstRow: {
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
  },
  lastRow: {
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignSelf: {
    alignSelf: 'flex-start',
  },
  titleTxt: {
    ...Typography.LABEL_MEDIUM,
    color: Colors.PRIMARY_900,
    alignSelf: 'center',
    textAlign: 'center',
    width: 100,
    flexWrap: 'wrap',
  },
  cellBoldTxt: {
    ...Typography.CAPTION_MEDIUM,
    color: Colors.PRIMARY_900,
  },
  cellTxt: {
    ...Typography.CAPTION,
    color: Colors.PRIMARY_900,
  },
  spaceTop: {
    marginTop: 5,
  },
  borderRight: {
    borderRightWidth: 1,
    borderColor: Colors.PRIMARY_300_SESSION_PLAN,
  },
  flex: {
    flex: 1,
  },
  cornerTab: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 8,
    borderTopWidth: 8,
    borderRightColor: 'transparent',
    borderTopColor: '#B5B5B8',
    transform: [{rotate: '90deg'}],
    position: 'absolute',
    top: 0,
    right: 0,
  },
  ph_0: {
    paddingHorizontal: 0,
  },
});

export default compose(
  withDatabase,
  withObservables(['sets'], ({database, sets}: any) => {
    return {
      events: database
        .get('events')
        .query(
          Q.where('set_id', Q.oneOf(sets.map((set: any) => set.id))),
          Q.where('deleted_at', null),
          Q.sortBy('created_at', Q.asc),
        ),
    };
  }),
)(FrequencyTable);
